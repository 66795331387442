/*
 *  Copyright (C) 2022 ScrappySoft - All Rights Reserved
 *  Unauthorized copying of this file or deriving works therefrom, via any medium, is strictly prohibited
 *  Proprietary and confidential
 *  <dev@scrappysoft.com>
 */

export default class TimedPromise {
  // return a Promise that will reject after timeout milliseconds
  static rejectAfter(timeout: number) {
    return new Promise((_, reject) => {
      setTimeout(reject.bind(null, new Error('promise timed out')), timeout);
    });
  }

  /**
   * pair the promise with a timeout and place in a race
   * @param promise
   * @param timeout in msec
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static timeout(promise: Promise<any>, timeout: number): Promise<Awaited<any>> {
    return Promise.race([promise, TimedPromise.rejectAfter(timeout)]);
  }

  /**
   * like Promise.all(), but with a timeout argument that will cause the batch to fail if it completes before the others
   * @param promises
   * @param timeout in msec
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static all(promises: Promise<any>[], timeout: number): Promise<Awaited<any>[]> {
    // given an array of promises, convert to an array of promise pairs -
    // the original, and a rejectAfter timed promise - in a race pair,
    // so the first one of each pair wins.
    // this means that the Promise.all will reject if any do not complete before the timeout
    return Promise.all(promises.map(p => {
      return TimedPromise.timeout(p, timeout);
    }));
  }
}
