export const userData = {
  'Zachary': {
    'password': '$2a$08$Sl9BowFXd.mB53xSPaIKD.fbOYzEI24ERDGs4lYMYnrVel9ofLk4i', // fish
    'langSheets': {
      'Latein': '10Hmf6HJkikPegU-lPXPOXRylfdhqxH8-IA_RVdWhwDg',
      'Latein Wiederholung': '1q-kA-uLO9JsAspv_TQKgWmSVBUOpjPKMN1DR86t7G8Y',
      'Latein Enden': '1RZiOupO6zA53-ZQjQSvKYsn9Mm1wHodGstyjgxw5hYI',
      'Latein Verbs': '1mOhltwMjt_S1Ec3P8kAv2meVdhQ0i6cueMI6KvtlcvA',
      'Englisch': '1-jAAyALW4ZwnV1DVVp2moUyFVcKSfjfmUIQiI2Fh23Q',
    },
  },
  'Ryan': {
    'password': '$2a$08$Wx2smpOPEnHpXATFN62pD.oA0LrqvIGYzDpaMeFCHXWknqld1Oycu', // eggs
    'langSheets': {
      'Englisch': '1nyNXRz6pi9jnexl5XcSxhpFWAIp9W4opv6N1l2sKdrU',
      'Fran\u00E7ais': '1vICbig-mf_FFhG3KgaiKrbsvbN0Y8FZTQYpobZLu4Dk',
    },
  },
  'Lexi': {
    'password': '$2a$08$eUGhBnzkdtgb2hJNzSVzOe3TcDnJS.FBsmdBmNJ0ozas5DcVyOUdi', // malfoy
    'langSheets': {
      'Englisch': '10HrhQFlRRWZ2VS7LxUducGrIx1v4rgItfAQHJRfOeoU',
      'Mathe': '1SdgIyo-FcSdp4l18aVr25FH0ZrLzwMhTcpJ_yl-e7n0',
    },
  },
  'qa': {
    'password': '$2a$08$Fh26POcSOYlaD3xAVW/ja.ymX.9HacnZ16.W1k.7A9ELK6NUlAyue', // loml
    'langSheets': {
      '--apptest--': '1yDgEaPq3Z-EoGNlKf_fgU5QIa9IXFLQfdjF_J-gn44E',
    },
  },
};

