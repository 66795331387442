import {userData} from './userData';
import {LangSpreadsheets, User} from './User';

interface IUserRecord {
  password: string,
  langSheets: LangSpreadsheets,
}

export class UserDAO {
  static getUsers() {
    const users: User[] = [];

    for (const [name, data] of Object.entries(userData)) {
      const userRecord: IUserRecord = <IUserRecord>data;
      users.push(new User(name, userRecord.password, userRecord.langSheets));
    }

    return users;
  }
}
