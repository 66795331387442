/*
 *  Copyright (C) 2022 ScrappySoft - All Rights Reserved
 *  Unauthorized copying of this file or deriving works therefrom, via any medium, is strictly prohibited
 *  Proprietary and confidential
 *  <dev@scrappysoft.com>
 */

export interface LogEntry {
  timestamp: number,
  message: string,
  data?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MAX_MESSAGES = 100;

interface GlobalLogData {
  displayEnabled: boolean,
  logBuffer: LogEntry[],
}

let _instance: Logger | undefined;

// noinspection JSUnusedGlobalSymbols
export default class Logger {
  private _lcLogger: GlobalLogData;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof globalThis._lcLogger === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      globalThis._lcLogger = <GlobalLogData>{
        displayEnabled: false,
        logBuffer: [],
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._lcLogger = globalThis._lcLogger;
  }

  static instance(): Logger {
    if (! _instance) {
      _instance = new Logger();
    }
    return _instance;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static log(message: string, data?: any): void {
    return Logger.instance().log(message, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static error(message: string, data?: any): void {
    return Logger.instance().error(message, data);
  }

  static enableDisplay(): void {
    return Logger.instance().enableDisplay();
  }

  static disableDisplay(): void {
    return Logger.instance().disableDisplay();
  }

  static isDisplayEnabled(): boolean {
    return Logger.instance().isDisplayEnabled();
  }

  static reset(): void {
    return Logger.instance().reset();
  }

  static entries(): LogEntry[] {
    return Logger.instance().entries();
  }

  ////

  enableDisplay(): void {
    this._lcLogger.displayEnabled = true;
  }

  disableDisplay(): void {
    this._lcLogger.displayEnabled = false;
  }

  isDisplayEnabled(): boolean {
    return this._lcLogger.displayEnabled;
  }

  reset(): void {
    this._lcLogger.logBuffer.length = 0;
  }

  entries(): LogEntry[] {
    return this._lcLogger.logBuffer;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _record(message: string, data?: any): void {
    // save to buffer, pruning as needed
    if (this._lcLogger.logBuffer.length >= MAX_MESSAGES) {
      this._lcLogger.logBuffer.shift();
    }
    const timestamp = Date.now();
    this._lcLogger.logBuffer.push({timestamp, message, data});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(message: string, data?: any): void {
    this._record(message, data);
    if (data) {
      console.log(message, data);
    } else {
      console.log(message);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: string, data?: any): void {
    this._record(message, data);
    if (data) {
      console.error(message, data);
    } else {
      console.error(message);
    }
  }
}
