import { Link, Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import strftime from 'strftime';
import Collapsible from 'react-collapsible';
import packageJson from '../../package.json';
import Logger, { LogEntry } from '../utils/Logger';

const VERSION_URL = '/version.txt';

const Layout = () => {
  const location = useLocation();
  const state = Object.assign({}, location?.state);

  const nameLang = location?.state?.userName ? `${location?.state?.userName} / ${location?.state?.language}` : '';
  const [curVersion, setCurVersion] = useState<string>(packageJson.version);
  const [loggingEnabled, setLoggingEnabled] = useState<boolean>(Logger.isDisplayEnabled());
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);

  // on mount
  useEffect(() => {
    const checkVersion = async () => {
      const timeout = 10000; // 10 sec
      let timeoutId = null;

      try {
        const controller = new AbortController();
        timeoutId = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(VERSION_URL, {
          cache: 'no-cache',
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
          },
          signal: controller.signal,
        });
        clearTimeout(timeoutId);
        if (response.status === 200) {
          const currentVersion = await response.text();
          setCurVersion(currentVersion);
        }
      } catch (err) {
        Logger.log('error fetching latest version info', err);
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        // otherwise, just swallow
      }
    };

    const getLogEntries = () => {
      const newEntries = Logger.entries();
      setLogEntries(newEntries);
    };
    getLogEntries();
    const logInterval = window.setInterval(getLogEntries, 1000); // 1 sec

    // noinspection JSIgnoredPromiseFromCall
    checkVersion();
    const versionInterval = window.setInterval(checkVersion, 60000); // 60 sec

    // clear on unload
    return (() => {
      window.clearInterval(logInterval);
      window.clearInterval(versionInterval);
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const confirmLeave = (event: any) => {
    if (event.shiftKey) {
      if (Logger.isDisplayEnabled()) {
        Logger.disableDisplay();
        setLoggingEnabled(false);
        console.log('logging disabled');
        Logger.log('logging disabled');
      } else {
        Logger.enableDisplay();
        setLoggingEnabled(true);
        console.log('logging enabled');
        Logger.log('logging enabled');
      }
      event.preventDefault();
      return;
    }

    if (location.pathname !== '/' && !window.confirm('Are you sure you want to leave?')) {
      event.preventDefault();
    }
  };

  const renderSession = (): JSX.Element => {
    if (!nameLang) {
      return (
        <div/>
      );
    }

    return (
      <Box display="flex" justifyItems="end" alignItems="center" sx={{margin: '10pt'}}>
        <Box display="flex" sx={{color: 'white'}}>
          {nameLang}
        </Box>
        <Box display="flex" sx={{color: 'white', marginLeft: '20pt'}}>
          <Link to='/' onClick={confirmLeave} state={{logout: true}} style={{color: 'white', textDecoration: 'none', fontSize: '10pt'}}>
            Logout
          </Link>
        </Box>
      </Box>
    );
  };

  const renderUpdateAvailable = (): JSX.Element => {
    if (curVersion && curVersion.trim() !== packageJson.version.trim()) {
      return (
        <Box sx={{color: 'red', marginLeft: '10px', marginTop: '3px', fontVariant: 'italic', fontSize: '9pt'}}>
          UPDATE AVAILABLE: v{curVersion}
        </Box>
      );
    }

    return (
      <div/>
    );
  };

  const renderLog = (): JSX.Element => {
    if (loggingEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const renderLogDetail = (data: any): JSX.Element => {
        if (data) {
          return (
            <Collapsible trigger="›" triggerWhenOpen="⌄">
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </Collapsible>
          );
        }

        return (
          <div/>
        );
      };

      return (
        <Box display="flex" flexDirection="column" alignItems="left" sx={{margin: '10pt', overflow: 'auto', maxHeight: '300px'}}>
          {logEntries.map((e, i) => {
            return (
              <Box key={`logentry-${i}`} display="flex" justifyItems="left" sx={{fontSize: '8pt'}}>
                <Box sx={{fontStyle: 'italic', paddingRight: '6px', whiteSpace: 'nowrap'}}>{strftime('%F.%T', new Date(e.timestamp))}</Box>
                <Box sx={{paddingRight: '6px'}}>{e.message}</Box>
                {renderLogDetail(e.data)}
              </Box>
            );
          })
          }
        </Box>
      );
    }

    return (
      <div/>
    );
  };

  return (
    <div>
      <nav>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{margin: '10pt'}}>
          <Link to='/' state={state} onClick={confirmLeave}>
            <Box display="flex" justifyItems="start" alignItems="center" sx={{margin: '10pt'}}>
              <Box display="flex">
                <img src='/images/logo192.png' style={{height: '40px', backgroundColor: loggingEnabled ? 'red' : '#282c34'}} alt="logo"/>
              </Box>
              <Box sx={{ flexDirection: 'column' }}>
                <Box sx={{color: 'white', marginLeft: '10px'}}>
                  LearnCards
                </Box>
                <Box sx={{color: 'grey', marginLeft: '10px', marginTop: '3px', fontVariant: 'italic', fontSize: '7pt'}}>
                  v{packageJson.version}
                </Box>
                {renderUpdateAvailable()}
              </Box>
            </Box>
          </Link>

          {renderSession()}
        </Box>
      </nav>

      <Outlet />

      {renderLog()}
    </div>
  );
};

export default Layout;
