import { QuizItemDAO } from '../models/QuizItemDAO';
import { QuizItemState } from '../models/QuizItem';
import {
  BatchRequest,
  GSheet,
  ICellValueType,
  IFormattableListValue,
  IFormattedCellValue,
  IListValue,
  ISpreadsheetData,
  IUpdateCellValues
} from '../models/GSheet';
import strftime from 'strftime';
import fastChunkString from 'fast-chunk-string';
import { cloneDeep } from 'lodash';
import Mutex from '../utils/Mutex';
import TimedPromise from '../utils/TimedPromise';
import Logger from '../utils/Logger';

export interface IQuizEntry {
  index: number,
  quizEntry: string,
  answers: string[],
  sheetName: string,
}

type IQuizStateData = {
  [key in QuizItemState]?: IQuizEntry[]
}

export interface IQuizEntryDuplicate {
  quizEntry: string,
  quizEntries: IQuizEntry[],
}

export type IQuizData = {
  sheetNames: string[],
  stateData: IQuizStateData,
}

export interface IQuizSetEntry extends IQuizEntry {
  state: QuizItemState,
  tries: number,
  responses: IQuizResponses[],
  isRevealed: boolean,
  isCorrect: boolean,
  isPerfected: boolean,
}

export type IQuizResponses = string[];

export interface IQuizProblemAnswer {
  quizSetEntryIndex: number,
  answerIndex: number,
}

type IQuizControlPercentage = {
  [key in QuizItemState]?: number
}

interface IQuizControlData {
  percentInSet: IQuizControlPercentage,
  masteredStreak: number,
  focusModeCount: number,
}

export interface IQuizSet {
  setEntries: IQuizSetEntry[],
  startedAt: Date,
  round: number,
  isRoundComplete: boolean,
  areAllRoundsComplete: boolean,
  completedAt?: Date,
  sessionName: string,
  quizControlData: IQuizControlData,
  isNewOnly?: boolean,
  isFocusMode?: boolean,
}

export interface IQuizStateSummary {
  entries: IQuizSetEntry[],
  correctCount: number,
  correctPercentage: number,
  missedEntries: IQuizSetEntry[],
  missedCount: number,
  missedPercentage: number,
  revealedCount: number,
}

type IQuizStateSummaries = {
  [key in QuizItemState]?: IQuizStateSummary
}

export interface IQuizStats {
  currentIndex: number,
  areAllCorrect: boolean,
  entryCount: number,
  completed: number,
  totalPercentage: number,
  correctCount: number,
  correctPercentage: number,
  missedCount: number,
  missedPercentage: number,
  revealedCount: number,
  perfectedCount: number,
  startedAt: Date,
  completedAt?: Date,
  duration: string,
  averageSeconds: number,
  summary?: IQuizStateSummaries,
  problemAnswers?: {
    quizProblemAnswer: IQuizProblemAnswer,
    state: string,
    quizEntry: string,
    answer: string,
  }[],
  prevQuizStats?: IQuizStats[],
}

export interface IQuizRegisterEntry {
  quizEntry: string,
  firstQuizAt?: Date,
  lastQuizAt?: Date,
  correctCount?: number,
  incorrectCount?: number,
  currentIncorrectStreak?: number,
  masteredStreak?: number,
}

export interface IQuizRegister {
  entries: IQuizRegisterEntry[],
}

export type QuizScope =
  | 'start'
  | 'resume'
  | 'newOnly'
  | 'focusMode'
  ;

export const TRACE_ENABLED = true;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trace = (message?: any, ...optionalParams: any[]): void => {
  if (TRACE_ENABLED) {
    const msg = `QuizManager:${message}`;
    if (optionalParams && optionalParams.length) {
      Logger.log(msg, optionalParams);
    } else {
      Logger.log(msg);
    }
  }
};

const mutex = new Mutex();

const STATE_COLUMN = 15;
const CELL_CHAR_LIMIT = 48000; // Google has 50k limit per cell, and leave some buffer
const TIMED_PROMISE_TIMEOUT = 20 * 1000;

export class QuizManager {
  private _initialized = false;
  private _quizItemDAO: QuizItemDAO;
  private _sheetNames: string[] = [];
  private _allSheetNames: string[] = [];
  private _openSessionSheetName: string | null = null;
  private _spreadsheetData: ISpreadsheetData = {};
  private _duplicates: IQuizEntryDuplicate[] = [];
  private _quizData: IQuizData | null = null;
  private _quizControlData: IQuizControlData = {
    percentInSet: {
      'new': 100,
      'retry': 100,
      'confirm': 100,
      'good': 30,
      'mastered': 20,
    },
    masteredStreak: 3,
    focusModeCount: 10,
  };
  private _quizRegister: IQuizRegister = {
    entries: [],
  };

  constructor(
    private _userName: string,
    private _language: string,
    private _spreadsheetId: string,
  ) {
    this._quizItemDAO = new QuizItemDAO(_spreadsheetId);
  }


  private static interestingSheet(name: string): boolean {
    return name.startsWith('new') || ['retry', 'confirm', 'good', 'mastered', 'perfected', 'control', 'register'].includes(name);
  }

  private async _initSheetNames(allSheetNames?: string[]): Promise<void> {
    if (allSheetNames) {
      this._allSheetNames = allSheetNames;
    } else {
      this._allSheetNames = await this._quizItemDAO.getSheetNames();
    }

    this._sheetNames = this._allSheetNames.filter(name => name && QuizManager.interestingSheet(name));
    this._openSessionSheetName = this._allSheetNames.find(s => s && s.startsWith('testing ')) || null;
    trace('sheet names', {sheetNames: this._sheetNames, allSheetNames: this._allSheetNames, openSessionSheetName: this._openSessionSheetName});
  }


  private async _initLoadSpreadsheetData(): Promise<void> {
    this._spreadsheetData = await this._quizItemDAO.getSpreadsheetData(this._sheetNames) || {};
    trace('sheet data', this._spreadsheetData);
  }

  /**
   * quizData: load all data from the spreadsheet
   */
  private async _initQuizData(): Promise<IQuizData> {
    trace('getting quiz data');
    if (!this._quizData) {
      trace('fetching new quiz data');

      // look for state and control sheets and process, skipping rest
      const sheetNames = this._sheetNames;
      this._quizData = {
        sheetNames,
        stateData: {},
      };
      for (const sheetName of sheetNames) {
        if (sheetName.startsWith('new')) {
          const entries: IQuizEntry[] = this._getEntries(sheetName);
          if (entries.length) {
            if (!this._quizData.stateData.new) {
              this._quizData.stateData.new = entries;
            } else {
              this._quizData.stateData.new.push(...entries);
            }
          }
        } else if (['retry', 'confirm', 'good', 'mastered', 'perfected'].includes(sheetName)) {
          // NB: include perfected in the quizData, but filter it out when selecting entries for a quiz
          // this way, they are available for dup detection
          const entries = this._getEntries(sheetName);
          if (entries) {
            this._quizData.stateData[<QuizItemState>sheetName] = entries;
          }
        } else if (sheetName.startsWith('hold')) {
          // skip held data pages
          trace(`skipping: ${sheetName}`);
        } else if (!['control', 'register'].includes(sheetName)) {
          // skip other tabs, eg, stats and logs
          trace(`skipping: ${sheetName}`);
        }
      }
    }

    return this._quizData;
  }

  private static _quizDataEntryHash(quizData: IQuizData): { [key: string]: IQuizEntry[] } {
    const quizDataEntries: { [key: string]: IQuizEntry[] } = {};
    for (const state of Object.keys(quizData.stateData)) {
      const entries = quizData?.stateData[<QuizItemState>state] || [];
      for (const entry of entries) {
        if (quizDataEntries[entry.quizEntry]) {
          quizDataEntries[entry.quizEntry].push(entry);
        } else {
          quizDataEntries[entry.quizEntry] = [entry];
        }
      }
    }
    return quizDataEntries;
  }


  private async _detectDuplicates(forceReload = false): Promise<IQuizEntryDuplicate[]> {
    if (forceReload) {
      this._quizData = null;
      await this._initLoadSpreadsheetData();
    }
    const quizData = await this._initQuizData();

    // save entries for duplicate check in hash, keyed by quiz word
    const dupCheck: {[key: string]: IQuizEntry[]} = QuizManager._quizDataEntryHash(quizData);

    // find any duplicate entries, where any term has more than one element in its list
    for (const quizEntry of Object.keys(dupCheck)) {
      const quizEntries: IQuizEntry[] = dupCheck[quizEntry];
      if (quizEntries.length > 1) {
        const dup: IQuizEntryDuplicate = {
          quizEntry,
          quizEntries,
        };
        this._duplicates.push(dup);
      }
    }

    trace('duplicates', this._duplicates);
    return this._duplicates;
  }

  /**
   * merge expired new sheets into retry
   */
  private async _initPromoteNewItems(): Promise<string[]> {
    const sheetNames = this._sheetNames;
    let updatedSheetNames = sheetNames.concat([]);
    const newSheets = sheetNames.filter(n => n && n.startsWith('new'));

    let isPromoted = false;

    const now = Date.now();
    for (const newSheet of newSheets) {
      const dateStr = newSheet.replace(/^.*(20\d\d[-.]\d\d[-.]\d\d)\s*$/, '$1');
      if (dateStr) {
        const date = Date.parse(dateStr.replace(/\./, '-'));
        if (now >= date) {
          // first time through, populate sheet data cache
          if (!isPromoted) {
            await this._initLoadSpreadsheetData();
          }
          isPromoted = true;

          // get sheet data from cache
          const rows: IListValue = this._spreadsheetData[newSheet] || [[]];

          // move data from expired new sheet to Retry sheet and delete or rename source sheet
          const newSourceSheetName = sheetNames.includes('new') ? null : 'new';
          updatedSheetNames = await this._quizItemDAO.moveRowsFromSheet(newSheet, 'retry', rows, newSourceSheetName);
          await this._initSheetNames(updatedSheetNames);
        }
      }
    }

    // NB: sheet data cache is now wrong if promotion happened,
    // so clear it here - should be repopulated in a subsequent step
    if (isPromoted) {
      this._spreadsheetData = {};
    }

    return updatedSheetNames;
  }


  private _getControlData(): IQuizControlData {
    const rows: IListValue = this._spreadsheetData['control'] || [[]];

    for (const row of rows) {
      const [label, value] = row;

      // only interested in labeled rows
      if (!label) {
        continue;
      }

      if (label.endsWith('percent')) {
        const state: QuizItemState = label.replace(/^(new|retry|confirm|good|mastered).*/, '$1');
        this._quizControlData.percentInSet[state] = parseInt(value);
      } else if (label === 'mastered streak') {
        this._quizControlData.masteredStreak = parseInt(value);
      } else if (label === 'focus mode count') {
        this._quizControlData.focusModeCount = parseInt(value);
      }
    }

    trace('control data', this._quizControlData);
    return this._quizControlData;
  }

  private _getRegister(): IQuizRegister {
    const rows: IListValue = this._spreadsheetData['register'] || [[]];

    this._quizRegister.entries = [];
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i];
      const [quizEntry, firstQuizAt, lastQuizAt, correctCount, incorrectCount, currentIncorrectStreak, masteredStreak] = row;

      const entry: IQuizRegisterEntry = {
        quizEntry,
        firstQuizAt: firstQuizAt?.length ? new Date(Date.parse(firstQuizAt)) : undefined,
        lastQuizAt: lastQuizAt?.length ? new Date(Date.parse(lastQuizAt)) : undefined,
        correctCount,
        incorrectCount,
        currentIncorrectStreak,
        masteredStreak,
      };

      this._quizRegister.entries.push(entry);
    }

    return this._quizRegister;
  }

  private async _init(): Promise<void> {
    // grab mutex lock
    const unlock = await mutex.lock();

    // do init, exclusively
    try {
      if (!this._initialized) {
        await this._initSheetNames();
        await this._initPromoteNewItems();
        await this._initLoadSpreadsheetData();
        await this._detectDuplicates();
        this._getControlData();
        this._getRegister();

        this._initialized = true;
      }
    } finally {
      unlock();
    }
  }


  /**
   * process rows from the sheet and convert to entries
   * @param sheetName
   */
  private _getEntries(sheetName: string): IQuizEntry[] {
    // get data from cache
    const rows: IListValue = this._spreadsheetData[sheetName] || [[]];

    // convert to entries
    // noinspection UnnecessaryLocalVariableJS
    const entries: IQuizEntry[] = rows.map((row, index) => ({
      index: index + 1, // zero based array, 1 based sheet
      quizEntry: row[0],
      answers: row.slice(1),
      sheetName,
    })).filter((e: IQuizEntry) => e.quizEntry);

    return entries;
  }


  /**
   * duplicates: return any duplicates found
   */
  async duplicates(forceReload = false): Promise<IQuizEntryDuplicate[]> {
    await this._init();
    if (forceReload) {
      await this._detectDuplicates(forceReload);
    }

    return this._duplicates;
  }


  /**
   * quizData: load all data from the spreadsheet
   */
  async quizData(): Promise<IQuizData> {
    if (!this._quizData) {
      await this._init();
    }

    if (this._quizData) {
      return this._quizData;
    }

    throw new Error('quizData: not initialized');
  }


  /**
   * return open session sheet name, or null if none found
   */
  async openSessionSheetName(): Promise<string | null> {
    // init loads spreadsheet names and data
    await this._init();

    trace('openSessionSheetName', this._openSessionSheetName);
    return this._openSessionSheetName;
  }


  /**
   * clean up any existing open session sheets and marking as abandoned
   */
  async abandonOpenSession(): Promise<void> {
    await this._init();
    if (this._openSessionSheetName) {
      const data: ISpreadsheetData = await this._quizItemDAO.getSpreadsheetData([this._openSessionSheetName]) || {};
      const values = data[this._openSessionSheetName];
      if (values) {
        const updateCellValuesSet: IUpdateCellValues[] = [];

        // find internal state and clear
        let savedStateRowIndex = null;
        for (let i = 0; i < values.length; i++) {
          const row = values[i];
          if (row.length >= STATE_COLUMN && row[STATE_COLUMN - 1] && row[STATE_COLUMN - 1].startsWith('-- INTERNAL STATE')) {
            savedStateRowIndex = i;
            break;
          }
        }
        if (savedStateRowIndex) {
          const savedStateRow = values[savedStateRowIndex];
          const match = savedStateRow[STATE_COLUMN - 1].match(/.*: (\d+).*/);
          const chunkCount = parseInt(match[1] || 1);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const emptyCells: any[] = Array.from(Array(chunkCount + 1), () => undefined);
          const updateCellValues: IUpdateCellValues = {
            rowIndex: savedStateRowIndex,
            columnIndex: STATE_COLUMN - 1,
            values: [emptyCells],
          };
          updateCellValuesSet.push(updateCellValues);
        }

        // update Completed At to be Abandoned At
        const updateCellValues: IUpdateCellValues = {
          rowIndex: 1,
          columnIndex: 0,
          values: [['Abandoned At', strftime('%F %T', new Date())]],
        };
        updateCellValuesSet.push(updateCellValues);

        const newSheetName = this._openSessionSheetName.replace(/^testing /, 'test ');
        await this._quizItemDAO.updateSheet(this._openSessionSheetName, updateCellValuesSet, newSheetName);
      }
    }
  }

  private _entriesToSetEntries(data: IQuizData, state: QuizItemState): IQuizSetEntry[] {
    const quizEntries: IQuizEntry[] | undefined = data.stateData[state];
    const setEntries: IQuizSetEntry[] = [];

    if (quizEntries) {
      for (const entry of quizEntries) {
        setEntries.push({
          state,
          tries: 0,
          index: entry.index,
          quizEntry: entry.quizEntry,
          answers: entry.answers,
          sheetName: entry.sheetName,
          responses: [],
          isRevealed: false,
          isCorrect: false,
          isPerfected: false,
        });
      }
    }

    return setEntries;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _sheetStateInfoColumns(quizSet: IQuizSet, stats: IQuizStats): any[] {
    const internalStateString = JSON.stringify({
      quizSet,
      stats,
    });

    // split into chunks to avoid exceeding Google cell character limit, write to multiple cells
    const internalStateChunks = fastChunkString(internalStateString, {size: CELL_CHAR_LIMIT, unicodeAware: true});

    // don't go past Z column with marker cell and then chunk cells
    if (internalStateChunks.length > (25 - STATE_COLUMN)) {
      throw new Error('_sheetStateInfoColumns() max cells exceeded');
    }

    // shift right of any other columns so width not a problem and typically off-screen,
    // but not so far right as to be out of range
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const emptyCells: any[] = Array.from(Array(STATE_COLUMN - 1), () => undefined);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stateRow: any[] = emptyCells.concat(
      `-- INTERNAL STATE: ${internalStateChunks.length}`,
    ).concat(internalStateChunks);

    return [
      [],
      [],
      [],
      stateRow,
    ];
  }


  private async _refreshSavedQuizSet(state: {quizSet: IQuizSet, stats: IQuizStats}): Promise<{quizSet: IQuizSet, stats: IQuizStats}> {
    // get current quiz data (hopefully already cached)
    const quizData: IQuizData = await this._initQuizData();

    // save entries in hash, keyed by quiz word
    const quizDataEntries: {[key: string]: IQuizEntry[]} = QuizManager._quizDataEntryHash(quizData);

    // quick lookup routine
    const findEntry = (quizEntry: string): IQuizEntry[] | null => quizDataEntries[quizEntry];

    // extract saved quiz set entries
    const stateQuizSetEntries: IQuizSetEntry[] = state.quizSet.setEntries;

    // holds the updated saved state set entries
    const newStateQuizSetEntries: IQuizSetEntry[] = [];

    // hash of encountered entries
    const stateEncountered = new Set<string>();

    // capture errors for later throwing
    const errorReasons: string[] = [];

    // iterate and compare to current sheet
    for (const entry of stateQuizSetEntries) {
      const current: IQuizEntry[] | null = findEntry(entry.quizEntry);

      // will throw error if not found in current sheet
      if (!current) {
        errorReasons.push(`missing entry ${entry.quizEntry}`);
        continue;
      }

      // if duplicates in current sheet, will throw error (but should be prevented already in UI)
      if (current.length > 1) {
        errorReasons.push('duplicates found in current sheet');
      }

      // if duplicate in saved state, only update the first one encountered, delete (don't copy) the rest
      if (stateEncountered.has(entry.quizEntry)) {
        continue;
      }

      const currentEntry: IQuizEntry = current[0];
      const newState: QuizItemState = currentEntry.sheetName.startsWith('new') ? 'new' : <QuizItemState>currentEntry.sheetName;

      // if new state is perfected, delete from quiz
      if (newState === 'perfected') {
        continue;
      }

      // update saved state entry to have current sheet/index and note that we encountered this entry
      const newEntry: IQuizSetEntry = Object.assign(entry, {
        index: currentEntry.index,
        sheetName: currentEntry.sheetName,
        state: newState,
      });
      newStateQuizSetEntries.push(newEntry);
      stateEncountered.add(entry.quizEntry);
    }

    // if we had any errors, bail now
    if (errorReasons.length) {
      throw new Error(`error resuming saved session: ${errorReasons.join(', ')}`);
    }

    // update current state set entries and return
    state.quizSet.setEntries = newStateQuizSetEntries;

    trace('refreshed state', state);
    return state;
  }


  /**
   * resume an open session quiz and return a quiz set to continue
   */
  async resumeQuiz(): Promise<{quizSet: IQuizSet, stats: IQuizStats}> {
    await this._init();
    let resumeError: Error = new Error('resumeQuiz: unexpected error');

    try {
      if (this._openSessionSheetName) {
        const data: ISpreadsheetData = await this._quizItemDAO.getSpreadsheetData([this._openSessionSheetName]) || {};
        const values = data[this._openSessionSheetName];
        if (values) {
          const savedStateRow = values.find(row => row.length >= STATE_COLUMN && row[STATE_COLUMN - 1] && row[STATE_COLUMN - 1].startsWith('-- INTERNAL STATE'));
          if (savedStateRow?.length) {
            // concat state cells back together and unmarshall
            const stateString = savedStateRow.slice(STATE_COLUMN).join('').trim();
            let state = JSON.parse(stateString);

            // adjust from JSON marshalling
            if (state?.quizSet?.startedAt) {
              state.quizSet.startedAt = new Date(state.quizSet.startedAt);
            }
            if (state?.stats?.startedAt) {
              state.stats.startedAt = new Date(state.stats.startedAt);
            }

            // update with current spreadsheet values, which may have been updated since save point
            // fixes what is possible, throws error if not
            state = await this._refreshSavedQuizSet(state);

            // success, presumably - return unmarshalled saved state
            return state;
          } else {
            resumeError = new Error('resumeQuiz: no session sheet JSON data found');
          }
        } else {
          resumeError = new Error('resumeQuiz: no session sheet data found');
        }
      } else {
        resumeError = new Error('resumeQuiz: no session sheet found');
      }

      // noinspection ExceptionCaughtLocallyJS
      throw resumeError;
    } catch (error) {
      trace('resumeQuiz: error resuming quiz', error);
      throw error;
    }
  }


  /**
   * get a quiz set for the app to use.
   * using the weight rules in control, assemble and shuffle a set of entries
   */
  async quizSet(quizScope: QuizScope = 'start'): Promise<IQuizSet> {
    const quizData = await this.quizData();
    const quizControlData = this._quizControlData;
    const setEntries: IQuizSetEntry[] = [];
    const newOnly = quizScope === 'newOnly';
    const focusMode = quizScope === 'focusMode';

    for (const state of Object.keys(quizData.stateData)) {
      if (state === 'perfected'
        || (newOnly && !state?.startsWith('new'))
        || (focusMode && state !== 'retry')) {
        continue;
      }

      const stateSet: IQuizSetEntry[] = this._entriesToSetEntries(quizData, <QuizItemState>state);
      if (!stateSet.length) {
        continue;
      }

      let limit;

      if (focusMode) {
        limit = quizControlData?.focusModeCount;
      } else {
        // determine percentage of elements to grab from this list,
        // shuffle and grab first N elements, add to total set
        const percentInSet = quizControlData?.percentInSet[<QuizItemState>state];
        const weight = (typeof percentInSet === 'number' ? percentInSet : 100) / 100;
        limit = Math.ceil(stateSet.length * weight);
      }
      if (!stateSet.length || !limit) {
        continue;
      }
      trace(`grabbing ${limit} of ${stateSet.length} for ${state}`);
      this._shuffleArray(stateSet);
      setEntries.push(...stateSet.slice(0, limit));
    }

    // shuffle order of final set
    this._shuffleArray(setEntries);

    // create session sheet
    const startedAt = new Date();
    const round = 1;
    const isRoundComplete = false;
    const areAllRoundsComplete = false;
    const formattedStart = strftime('%F %T', startedAt);
    const sessionName = `testing ${formattedStart}`;

    const quizSet: IQuizSet = {
      setEntries,
      startedAt,
      round,
      isRoundComplete,
      areAllRoundsComplete,
      sessionName,
      quizControlData,
      isNewOnly: newOnly,
      isFocusMode: focusMode,
    };
    const stats = this._calculateProgressStats([], null, false, -1, quizSet);

    const values = [
      ['Last Updated At', formattedStart],
      ['Started At', formattedStart],
    ].concat(this._sheetStateInfoColumns(quizSet, stats));
    const sessionSheetIndex = this._allSheetNames.filter(s => s && (s.startsWith('new') || s.startsWith('hold') || ['retry', 'confirm', 'good', 'mastered', 'perfected', 'register', 'control'].includes(s))).length;

    // create sheet
    await this._quizItemDAO.addSheet(sessionName, sessionSheetIndex, values);

    trace('quizSet', quizSet);
    return quizSet;
  }


  private static _nextState(state: QuizItemState, previous = false): QuizItemState {
    const states: QuizItemState[] = ['retry', 'confirm', 'good', 'mastered'];

    const curIndex = states.findIndex(s => s === state);
    if (curIndex < 0) {
      return state;
    }

    let nextIndex = previous ? curIndex - 1 : curIndex + 1;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex >= states.length) {
      nextIndex = states.length - 1;
    }

    return states[nextIndex];
  }

  private static _stateSummary(state: QuizItemState, quizSetEntries: IQuizSetEntry[], currentIndex: number): IQuizStateSummary {
    const entries = quizSetEntries.slice(0, currentIndex + 1).filter(q => q.state === state).map(e => cloneDeep(e));
    const entryCount = entries.length;
    const correctCount = entries.filter(q => q.isCorrect).length;
    const correctPercentage = Math.round((correctCount / entryCount) * 100);
    const missedEntries = entries.filter(q => q.tries > 1 || q.isRevealed).map(e => cloneDeep(e));
    const missedCount = missedEntries.length;
    const missedPercentage = Math.round((missedCount / entryCount) * 100);
    const revealedCount = entries.filter(q => q.isRevealed).length;

    return {
      entries,
      correctCount,
      correctPercentage,
      missedEntries,
      missedCount,
      missedPercentage,
      revealedCount,
    };
  }


  // noinspection SpellCheckingInspection
  private static _toHHMMSS(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor(totalSeconds / 60) % 60;
    const seconds = totalSeconds % 60;

    return [hours, minutes, seconds]
      .map(v => v < 10 ? '0' + v : v)
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };


  private _calculateProgressStats(
    quizSetEntries: IQuizSetEntry[],
    currentEntry: IQuizSetEntry | null,
    areAllCorrect: boolean,
    currentIndex: number,
    quizSet: IQuizSet,
    prevQuizStats?: IQuizStats[]): IQuizStats {
    let correctCount = 0;
    let missedCount = 0;
    let revealedCount = 0;
    let perfectedCount = 0;
    for (const entry of quizSetEntries) {
      if (entry.isRevealed) {
        revealedCount++;
        missedCount++;
      } else if (entry.tries === 1) {
        if (entry !== currentEntry) {
          correctCount++;
        } else if (areAllCorrect) {
          correctCount++;
        }
      } else if (entry.tries > 1) {
        missedCount++;
      }
      if (entry.isPerfected) {
        perfectedCount++;
      }
    }

    const entryCount = quizSetEntries.length;
    const completed = currentIndex + (areAllCorrect ? 1 : 0);
    const totalPercentage = Math.round((completed / entryCount) * 100) || 0;
    const missedPercentage = Math.round((missedCount / entryCount) * 100) || 0;
    const correctPercentage = Math.round((correctCount / entryCount) * 100) || 0;

    const endSec = (quizSet.completedAt ? quizSet.completedAt.getTime() : new Date().getTime());
    const startSec = (quizSet.startedAt ? quizSet.startedAt.getTime() : new Date().getTime());
    const durationSec = Math.round((endSec - startSec) / 1000) || 0;
    const duration = QuizManager._toHHMMSS(durationSec);
    const averageSeconds = (Math.round((durationSec * 100) / entryCount) / 100) || 0;

    return {
      currentIndex,
      areAllCorrect,
      entryCount,
      completed,
      totalPercentage,
      correctCount,
      correctPercentage,
      missedCount,
      missedPercentage,
      revealedCount,
      perfectedCount,
      startedAt: quizSet.startedAt,
      completedAt: quizSet.completedAt,
      duration,
      averageSeconds,
      prevQuizStats,
    };
  }

  private _buildUpdateRegisterSheet(currentEntry: IQuizSetEntry, curState: QuizItemState, destState: QuizItemState): { registerRequestsPromise: Promise<BatchRequest[]>, updatedDestState: QuizItemState } {
    // find or create corresponding entry
    let registerEntry = this._quizRegister.entries.find(e => e.quizEntry === currentEntry.quizEntry);
    if (!registerEntry?.quizEntry?.length) {
      registerEntry = {
        quizEntry: currentEntry.quizEntry,
      };
      this._quizRegister.entries.push(registerEntry);
    }

    // update entry in memory
    const now = new Date();
    if (!registerEntry.firstQuizAt) {
      registerEntry.firstQuizAt = now;
    }
    registerEntry.lastQuizAt = now;
    if (currentEntry.isCorrect) {
      registerEntry.correctCount = (Number(registerEntry.correctCount) || 0) + 1;
      registerEntry.currentIncorrectStreak = 0;

      // if mastered, move to perfected if streak above threshold
      if (curState === 'mastered') {
        registerEntry.masteredStreak = (Number(registerEntry.masteredStreak) || 0) + 1;
        if (registerEntry?.masteredStreak >= this._quizControlData.masteredStreak) {
          destState = 'perfected';
          currentEntry.isPerfected = true;
        }
      }
    } else {
      registerEntry.incorrectCount = (Number(registerEntry.incorrectCount) || 0) + 1;
      registerEntry.currentIncorrectStreak = (Number(registerEntry.currentIncorrectStreak) || 0) + 1;

      // 0 means been there and regressed, otherwise leave as undefined
      if (curState === 'mastered') {
        registerEntry.masteredStreak = 0;
      }
    }

    // write to sheet
    const values: IListValue = this._quizRegister.entries.filter((e: IQuizRegisterEntry) => e.quizEntry).map(e => [
      e.quizEntry,
      e.firstQuizAt ? strftime('%F %T', e.firstQuizAt) : '',
      e.lastQuizAt ? strftime('%F %T', e.lastQuizAt) : '',
      Number(e.correctCount) || undefined,
      Number(e.incorrectCount) || undefined,
      Number(e.currentIncorrectStreak) || undefined,
      Number(e.masteredStreak) || undefined,
    ]);
    const headerRow = this._spreadsheetData['register'][0];
    values.unshift(headerRow?.length ? headerRow : [
      'quiz entry',
      'first quiz at',
      'last quiz at',
      'correct count',
      'incorrect count',
      'current incorrect streak',
      'mastered streak',
    ]);

    trace('register values', values);

    // let it update asynchronously
    const promise: Promise<BatchRequest[]> = this._quizItemDAO.buildReplaceSheet('register', values);
    return {registerRequestsPromise: promise, updatedDestState: destState};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static _transposeArray = (matrix: any[][]) => {
    return matrix.reduce((r, a, i, {length}) => {
      a.forEach((v, j) => {
        r[j] = r[j] || new Array(length).fill('');
        r[j][i] = v;
      });
      return r;
    }, []);
  };


  // update the statistics sheet for this session
  private _buildUpdateSessionSheet(quizSet: IQuizSet, states: QuizItemState[], stats: IQuizStats): Promise<BatchRequest[]> {
    const sessionSheetName = quizSet.sessionName;

    const formatWord = (state: QuizItemState, entry: IQuizSetEntry, includeAttempts?: boolean): IFormattedCellValue => {
      // add the responses to the entry
      const attempts = (responses: IQuizResponses[]) => {
        return responses.slice(0, -1)
          .map((response) => `   • ${response.join(', ')}`)
          .join('\n').trimEnd().replace(/^(.)/, '\n$1');
      };

      // if incorrect, add the number of tries
      const value = entry.isCorrect || !includeAttempts ? entry.quizEntry : `${entry.quizEntry} (${entry.tries})`;

      const formatCell = (
        horizontalAlignment: 'LEFT' | 'CENTER' | 'RIGHT',
        hexColor: string,
        bold?: boolean,
        italic?: boolean,
      ): IFormattedCellValue => {
        const valueCell: IFormattedCellValue = {
          value,
          format: {
            horizontalAlignment,
            textFormat: {
              bold,
              italic,
              foregroundColorStyle: GSheet.hexColorToColorStyle(hexColor),
            },
          },
        };

        if (!includeAttempts) {
          return valueCell;
        }

        // format attempts more plainly
        const attemptsCell: IFormattedCellValue = {
          value: attempts(entry.responses),
          format: {
            textFormat: {
              bold: false,
              fontSize: 8,
              foregroundColorStyle: GSheet.hexColorToColorStyle('#222222'),
            },
          },
        };

        // concat to main result
        return GSheet.concatFormattedCells(valueCell, attemptsCell);
      };

      // items in mastered can get perfected
      if (state === 'mastered' && entry.isPerfected) {
        return formatCell('RIGHT', '#0d3ce3', true);
      }

      if (entry.isCorrect) {
        return formatCell('RIGHT', '#048a83');
      }

      // incorrect entry: left shifted, red, italic if revealed
      return formatCell('LEFT', '#8a1212', false, entry.isRevealed);
    };

    const wordDetails = (quizStats: IQuizStats): {allWords: IFormattableListValue, missedWords: IFormattableListValue} => { // eslint-disable-line @typescript-eslint/no-explicit-any
      // gather words by state, all words and missed words
      const allWords: IFormattableListValue = [];
      const missedWords: IFormattableListValue = [];
      states.forEach((state: QuizItemState) => {
        let addedAll = false;
        let addedMissed = false;
        if (quizStats.summary) {
          const stateSummary = quizStats.summary[state];
          if (stateSummary) {
            if (stateSummary.entries.length) {
              allWords.push(stateSummary.entries.map(e => formatWord(state, e)));
              addedAll = true;
            }

            if (stateSummary.missedEntries.length) {
              missedWords.push(stateSummary.missedEntries.map(e => formatWord(state, e, true)));
              addedMissed = true;
            }
          }
        }

        if (!addedAll) {
          allWords.push([]);
        }
        if (!addedMissed) {
          missedWords.push([]);
        }
      });

      return {allWords, missedWords};
    };

    const sectionHeader = (value: string, fontSize = 12): IFormattedCellValue => {
      return {
        value,
        format: {
          textFormat: {
            bold: true,
            fontSize,
          },
        },
      };
    };

    const stateHeader = (state: QuizItemState, includeCounts?: boolean): IFormattedCellValue => {
      let missedCount = 0;
      let entryCount = 0;

      if (stats.summary) {
        const stateSummary = stats.summary[state];
        if (stateSummary) {
          missedCount = stateSummary.missedCount;
          entryCount = stateSummary.entries.length;
        }
      }

      const stateName = state.charAt(0).toUpperCase() + state.slice(1);

      const valueCell: IFormattedCellValue = {
        value: ` ${stateName}`,
        format: {
          horizontalAlignment: 'CENTER',
          textFormat: {
            bold: true,
          },
        },
      };

      if (!includeCounts) {
        return valueCell;
      }

      const countsCell: IFormattedCellValue = {
        value: `  (${missedCount}/${entryCount}) `,
        format: {
          textFormat: {
            bold: false,
            fontSize: 9,
            foregroundColorStyle: GSheet.hexColorToColorStyle('#222222'),
          },
        },
      };

      return GSheet.concatFormattedCells(valueCell, countsCell);
    };

    const missedStateHeaders = states.map((state: QuizItemState) => stateHeader(state, true));
    const allStateHeaders = states.map((state: QuizItemState) => stateHeader(state));

    const statCell = (value: ICellValueType): IFormattedCellValue => {
      return {
        value,
        format: {
          horizontalAlignment: 'RIGHT',
        },
      };
    };

    const duplicates = []; // is separator row if it remains empty
    if (this._duplicates.length) {
      // add separator row before
      duplicates.push([]);

      const label: IFormattedCellValue = {
        value: 'Duplicate detected:',
        format: {
          textFormat: {
            bold: true,
          },
          backgroundColorStyle: GSheet.hexColorToColorStyle('#e5f50e'),
        }
      };
      for (const dup of this._duplicates) {
        const locations = dup.quizEntries.map(dup => `${dup.sheetName} [ ${dup.index} ]`);
        duplicates.push([label, dup.quizEntry].concat(locations));
      }

      // add separator row after
      duplicates.push([]);
    }

    const problemAnswers = []; // is separator row if it remains empty
    if (stats.problemAnswers?.length) {
      const label: IFormattedCellValue = {
        value: 'Problem reported:',
        format: {
          textFormat: {
            bold: true,
          },
          backgroundColorStyle: GSheet.hexColorToColorStyle('#e5f50e'),
        }
      };
      for (const problemAnswer of stats.problemAnswers) {
        problemAnswers.push([label, problemAnswer.state, problemAnswer.quizEntry, problemAnswer.answer]);
      }

      // separator row
      problemAnswers.push([]);
    }
    // always an extra separator row
    problemAnswers.push([]);

    // save internal state info for in-progress tests
    const stateInfo = stats.completedAt ? [[]] : this._sheetStateInfoColumns(quizSet, stats);

    const controls = quizSet.quizControlData;
    const updatedAt = new Date();

    // create stats rows, concat the transposed states rows as columns
    let values;
    if (quizSet.isFocusMode) {
      const endSec = (quizSet.completedAt ? quizSet.completedAt.getTime() : new Date().getTime());
      const startedAt = stats?.prevQuizStats?.length ? stats.prevQuizStats[0].startedAt : stats.startedAt;
      const startSec = (startedAt ? startedAt.getTime() : new Date().getTime());
      const durationSec = Math.round((endSec - startSec) / 1000) || 0;
      const duration = QuizManager._toHHMMSS(durationSec);
      const entryCount = stats?.prevQuizStats?.length ? stats.prevQuizStats[0].summary?.retry?.entries.length || 1 : stats.entryCount;
      const averageSeconds = (Math.round((durationSec * 100) / entryCount) / 100) || 0;

      const roundStatsList = stats.prevQuizStats?.length ? [...stats.prevQuizStats] : [];
      roundStatsList.push(stats);
      const roundHeaders = roundStatsList.map((roundStats: IQuizStats, index: number) => {
        const correctCount = roundStats.correctCount;
        const entryCount = roundStats.entryCount;
        return sectionHeader(`Round ${index + 1} : ${correctCount}/${entryCount}`, 10);
      });

      const roundWords: IFormattableListValue = [];
      for (const roundStats of roundStatsList) {
        const {allWords} = wordDetails(roundStats);
        roundWords.push(allWords.length ? allWords[0] : []);
      }
      const roundValues = QuizManager._transposeArray(roundWords);

      values = [
        ['Last Updated At', statCell(strftime('%F %T', updatedAt))],
        ['Started At', statCell(strftime('%F %T', startedAt)), undefined, 'Scope', `Focus mode (${quizSet.round})`],
        ['Completed At', statCell(stats.completedAt ? strftime('%F %T', stats.completedAt) : 'in progress')],
        ['Duration', statCell(duration), undefined, 'Controls:'],
        ['Entries', statCell(entryCount), undefined, 'focus mode count', statCell(`${controls.focusModeCount}`)],
        ['Average Sec', statCell(averageSeconds)],
        ['Revealed', statCell(stats.revealedCount)],
      ].concat(duplicates)
        .concat(problemAnswers)
        .concat([
          roundHeaders,
        ])
        .concat(roundValues)
        .concat(stateInfo);

      trace('stat values', {stats, values, roundValues});
    } else {
      const {allWords, missedWords} = wordDetails(stats);
      const allValues = QuizManager._transposeArray(allWords);
      const missedValues = QuizManager._transposeArray(missedWords);

      values = [
        ['Last Updated At', statCell(strftime('%F %T', updatedAt))],
        ['Started At', statCell(strftime('%F %T', stats.startedAt)), undefined, 'Scope', quizSet.isNewOnly ? 'New only' : 'Normal'],
        ['Completed At', statCell(stats.completedAt ? strftime('%F %T', stats.completedAt) : 'in progress')],
        ['Duration', statCell(stats.duration), undefined, 'Controls:'],
        ['Entries', statCell(stats.entryCount), undefined, 'new percent', statCell(`${controls.percentInSet['new']}`)],
        ['Completed', statCell(stats.completed), undefined, 'retry percent', statCell(`${controls.percentInSet['retry']}`)],
        ['Average Sec', statCell(stats.averageSeconds), undefined, 'confirm percent', statCell(`${controls.percentInSet['confirm']}`)],
        ['Correct', statCell(`${stats.correctPercentage}% (${stats.correctCount}/${stats.entryCount})`), undefined, 'good percent', statCell(`${controls.percentInSet['good']}`)],
        ['Missed', statCell(`${stats.missedPercentage}% (${stats.missedCount}/${stats.entryCount})`), undefined, 'mastered percent', statCell(`${controls.percentInSet['mastered']}`)],
        ['Revealed', statCell(stats.revealedCount), undefined, 'mastered streak', controls.masteredStreak],
        ['Perfected', statCell(stats.perfectedCount)],
      ].concat(duplicates)
        .concat(problemAnswers)
        .concat([
          [sectionHeader('Missed Words')],
          missedStateHeaders,
        ])
        .concat(missedValues)
        .concat([
          [],
          [],
          [],
          [sectionHeader('All Words')],
          allStateHeaders,
        ])
        .concat(allValues)
        .concat(stateInfo);

      trace('stat values', {stats, values, missedWords, missedValues});
    }

    const newSheetName = stats.completedAt ? sessionSheetName.replace(/testing /, 'test ') : undefined;
    if (newSheetName) {
      this._openSessionSheetName = null;
    }
    return this._quizItemDAO.buildReplaceSheet(sessionSheetName, values, newSheetName);
  }


  /**
   * update the quiz set to start a new focus round
   * @param quizSet
   * @param stats
   */
  resetFocusRound(quizSet: IQuizSet, stats: IQuizStats): {newQuizSet: IQuizSet, newStats: IQuizStats} {
    // clone the quiz
    const newQuizSet: IQuizSet = cloneDeep(quizSet);

    // remove correct items from the quiz set
    // if more entries remain to do, bump to next round
    const newSetEntries: IQuizSetEntry[] = newQuizSet.setEntries.filter(e => !e.isCorrect);
    if (newSetEntries.length) {
      newQuizSet.startedAt = new Date();
      newQuizSet.round++;
      newQuizSet.isRoundComplete = false;

      // reset testing state in each entry
      for (const setEntry of newSetEntries) {
        setEntry.tries = 0;
        setEntry.isCorrect = false;
        setEntry.isRevealed = false;
        setEntry.isPerfected = false;
        setEntry.responses.push([`<round ${newQuizSet.round}>`]);
      }

      // use updated entries
      newQuizSet.setEntries = newSetEntries;
    }

    // create new stats, chain saved old stats
    const newStats: IQuizStats = this._calculateProgressStats([], null, false, 0, newQuizSet);
    newStats.prevQuizStats = stats.prevQuizStats || [];
    const prevStats = cloneDeep(stats);
    prevStats.completedAt = new Date();
    prevStats.prevQuizStats = undefined;
    newStats.prevQuizStats.push(prevStats);

    // return new quiz and stats, as separate objects since they need to be saved in state explicitly
    return {newQuizSet, newStats};
  }


  /**
   * grade the given quiz set, update the sheet, return the stats
   * @param quizSet
   * @param currentIndex
   * @param responsesEntered
   * @param problemAnswers
   * @param completionCallback
   * @param prevQuizStats
   */
  async gradeQuiz(
    quizSet: IQuizSet,
    currentIndex: number,
    responsesEntered: IQuizResponses,
    problemAnswers: IQuizProblemAnswer[],
    completionCallback: (success: boolean, err?: unknown) => void,
    prevQuizStats?: IQuizStats[],
  ): Promise<IQuizStats> {
    let stats: IQuizStats;

    const quizSetEntries: IQuizSetEntry[] = quizSet.setEntries;
    const currentEntry = quizSetEntries[currentIndex];

    // check that every answer appears in a response, and that all are answered
    // inefficient On^2, but n is tiny here, so, don't care
    const areAllCorrect: boolean = currentEntry.answers.every(answer => responsesEntered.includes(answer));

    try {
      // all answers must be correctly supplied to be 'correct',
      // and the quiz cannot advance until the correct answer(s) given
      if (areAllCorrect) {
        let batchRequests: BatchRequest[] = [];

        currentEntry.isCorrect = currentEntry.tries === 1 && !currentEntry.isRevealed;

        // apply rules to determine next state for this item
        // - all items in new stay in new, only advanced by promotion after date
        // - item is correct iff all answers are correctly given
        // - correct items advance to next state (except new)
        // - incorrect items advance to previous state (minimum is retry)
        // - hitting reveal is the same as being incorrect
        // - if mastered and meets streak minimum, advance to perfected
        const curState: QuizItemState = currentEntry.state;
        let destState: QuizItemState = currentEntry.state;
        if (curState !== 'new') {
          if (currentEntry.isCorrect) {
            destState = QuizManager._nextState(curState);
          } else {
            destState = QuizManager._nextState(curState, true);
          }
        }

        // update register
        // do not await actual sheet update - let error get handled by the callback later
        const {registerRequestsPromise, updatedDestState} = this._buildUpdateRegisterSheet(currentEntry, curState, destState);
        destState = updatedDestState;

        // build request to update the register sheet
        const registerRequests: BatchRequest[] = await registerRequestsPromise;
        batchRequests = batchRequests.concat(registerRequests);

        // update progress percentages
        // we do this after updateRegisterSheet since that handles perfected
        stats = this._calculateProgressStats(quizSetEntries, currentEntry, areAllCorrect, currentIndex, quizSet, prevQuizStats);

        // handle updating state, which means also moving to correct sheet

        // NB: the in-memory version of the sheet remains as it was at the start of the quiz,
        // except for the shuffling, below
        if (destState !== curState) {
          const values = [
            [currentEntry.quizEntry, ...currentEntry.answers]
          ];

          // build request to move word from its current tab to the new state tab
          // NB: we never move from new via grading, so current state == sourceSheetName
          //     if requirements change, and we do move from new*, we will need to lookup the sheet
          const moveRequests: BatchRequest[] = await this._quizItemDAO.buildMoveRow(curState, currentEntry.index, destState, values);
          batchRequests = batchRequests.concat(moveRequests);

          // HACK: we don't wait for the above to return the updated spreadsheet,
          // but our entries are now partially incorrect with the above move.
          // we don't care about the row moved - its location doesn't matter any more,
          // but all the rows on the source sheet below it have now been shuffled up one row.
          // we simulate that adjustment, rather than actually sync to the current values.
          for (const entry of quizSetEntries) {
            if (entry.state === currentEntry.state && entry.index > currentEntry.index) {
              entry.index--;
            }
          }
        }

        // note when round is completed, refresh duplicates
        quizSet.isRoundComplete = currentIndex === stats.entryCount - 1;
        quizSet.areAllRoundsComplete = false;
        if (quizSet.isRoundComplete) {
          if (quizSet.isFocusMode) {
            // in focus mode, all entries must be answered in one try to be done
            quizSet.areAllRoundsComplete = quizSetEntries.filter(e => !e.isCorrect).length === 0;
          } else {
            // only one round in non-focus
            quizSet.areAllRoundsComplete = true;
          }
        }
        if (quizSet.areAllRoundsComplete) {
          stats.completedAt = new Date();
          await this._detectDuplicates(true);
        }

        // update state-specific statistics
        const summaries: IQuizStateSummaries = {};
        const states: QuizItemState[] = quizSet.isNewOnly ? ['new'] : (quizSet.isFocusMode ? ['retry'] : ['new', 'retry', 'confirm', 'good', 'mastered']);
        states.forEach((state: QuizItemState) => {
          summaries[state] = QuizManager._stateSummary(state, quizSetEntries, currentIndex);
        });
        stats.summary = summaries;

        // note any reported problems
        if (problemAnswers.length) {
          for (const problemAnswer of problemAnswers) {
            if (!stats.problemAnswers) {
              stats.problemAnswers = [];
            }

            const problemEntry = quizSetEntries[problemAnswer.quizSetEntryIndex];
            stats.problemAnswers.push({
              quizProblemAnswer: problemAnswer, // needed for unmarshalling
              state: problemEntry.state,
              quizEntry: problemEntry.quizEntry,
              answer: problemEntry.answers[problemAnswer.answerIndex],
            });
          }
        }

        // build request to update the session sheet
        const sessionRequests = await this._buildUpdateSessionSheet(quizSet, states, stats);
        batchRequests = batchRequests.concat(sessionRequests);

        // execute the requests in a batch via promise, wrapped with timeout
        const batchPromise = this._quizItemDAO.batchUpdate(batchRequests);
        TimedPromise.timeout(batchPromise, TIMED_PROMISE_TIMEOUT)
          .then(() => completionCallback(true))
          .catch((err) => {
            Logger.log('gradeQuiz() promise error', err);
            completionCallback(false, err);
          });

        return stats;
      } else {
        // no async promises needed, so just say we're done
        completionCallback(true);
      }
    } catch (err) {
      Logger.log('gradeQuiz() error', err);
      completionCallback(false, err);
    }

    // not complete, so just update stats
    stats = this._calculateProgressStats(quizSetEntries, currentEntry, areAllCorrect, currentIndex, quizSet, prevQuizStats);

    // return stats
    return stats;
  }
}
