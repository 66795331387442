// noinspection JSUnusedGlobalSymbols
import {
  BatchRequest,
  BatchResult,
  GSheet,
  IListValue,
  ISpreadsheetData,
  IUpdateCellValues
} from './GSheet';

export class QuizItemDAO {
  private _gSheet: GSheet;

  constructor(
    private _spreadsheetId: string,
  ) {
    this._gSheet = new GSheet(_spreadsheetId);
  }

  get spreadsheetId(): string {
    return this._spreadsheetId;
  }

  async getSheetNames(): Promise<string[]> {
    return this._gSheet.getSheetNames();
  }

  async getSpreadsheetData(sheetNames: string[]): Promise<ISpreadsheetData> {
    return this._gSheet.getSpreadsheetData(sheetNames);
  }

  async batchUpdate(requests: BatchRequest[]): Promise<BatchResult> {
    return this._gSheet.batchUpdate(requests);
  }

  async buildMoveRow(sourceSheetName: string, sourceRowIndex: number, destSheetName: string, values: IListValue): Promise<BatchRequest[]> {
    return this._gSheet.buildMoveRow(sourceSheetName, sourceRowIndex, destSheetName, values);
  }

  async moveRowsFromSheet(sourceSheetName: string, destSheetName: string, values: IListValue, newSourceSheetName: string | null): Promise<string[]> {
    return this._gSheet.moveRowsFromSheet(sourceSheetName, destSheetName, values, newSourceSheetName);
  }

  async addSheet(sheetName: string, sheetIndex: number, values: IListValue): Promise<string[]> {
    return this._gSheet.addSheet(sheetName, sheetIndex, values);
  }

  async buildReplaceSheet(sheetName: string, values: IListValue, newSheetName?: string): Promise<BatchRequest[]> {
    return this._gSheet.buildReplaceSheet(sheetName, values, newSheetName);
  }

  async updateSheet(sheetName: string, cellValues: IUpdateCellValues[], newSheetName?: string): Promise<void> {
    return this._gSheet.updateSheet(sheetName, cellValues, newSheetName);
  }
}
