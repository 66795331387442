// perhaps there is a better, less visibly hard-coded way to pass these values,
// like a fetch from an encrypted source, but ultimately, these are always findable
// without a backend to proxy or something since the values are needed in the browser
//
// best to just limit the scope of access and control the damage on a leak
//
export const sheetConfig = {
  clientId: '209463921931-7mr14vsiv9iubdoen8c6ihqe41l9o380.apps.googleusercontent.com',
  apiKey: 'AIzaSyBej0kYOFPDA_EjGRzmD7qv5_-4PB5RaGk',
  discoveryDocs: 'https://sheets.googleapis.com/$discovery/rest?version=v4',
  scopes: [
    'https://www.googleapis.com/auth/spreadsheets',
  ],
  redirectUris: [
    'https://localhost:3000',
  ]
};
