/*
 *  Copyright (C) 2022 ScrappySoft - All Rights Reserved
 *  Unauthorized copying of this file or deriving works therefrom, via any medium, is strictly prohibited
 *  Proprietary and confidential
 *  <dev@scrappysoft.com>
 */

// from https://github.com/mgtitimoli/await-mutex/blob/master/src/mutex.js
// copying here rather than importing via package.json
// converted to TypeScript

export default class Mutex {
  private _locking;
  private _locks: number;

  constructor() {
    this._locking = Promise.resolve();
    this._locks = 0;
  }

  // noinspection JSUnusedGlobalSymbols
  isLocked(): boolean {
    return this._locks > 0;
  }

  lock() {
    this._locks += 1;

    let unlockNext: () => void;

    const willLock = new Promise<void>(resolve => unlockNext = () => {
      this._locks -= 1;
      resolve();
    });

    const willUnlock = this._locking.then(() => unlockNext);

    this._locking = this._locking.then(() => willLock);

    return willUnlock;
  }
}
