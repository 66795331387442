import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './Home.css';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Input from '@mui/joy/Input';
import {Button} from '@mui/joy';
import Box from '@mui/joy/Box';
import {UserDAO} from '../models/UserDAO';
import {User} from '../models/User';
import Logger from '../utils/Logger';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userList: User[] = UserDAO.getUsers();
  const userNames: string[] = userList.map((u: User) => u.name);

  // on mount
  useEffect(() => {
    Logger.reset();
  }, []);

  let loggedInUser: User | null = null;
  if (location?.state?.userName) {
    loggedInUser = userList.find(u => u.name === location?.state?.userName) || null;
  }

  const [user, setUser] = useState<User | null>(loggedInUser);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(!!loggedInUser);
  const [language, setLanguage] = useState<string | undefined>(location?.state?.language);

  if (location?.state?.logout) {
    location.state.logout = undefined;
    setIsUserLoggedIn(false);
    setUser(null);
    setLanguage(undefined);
  }

  const renderUserSelector = ()  : JSX.Element => {
    if (isUserLoggedIn) {
      return (
        <div/>
      );
    }

    const selectUser = (name: string) => {
      const theUser = userList.find(u => u.name === name) || null;
      setUser(theUser);
    };

    return (
      <>
        <Box display="flex" sx={{alignItems: 'left', fontSize: '10pt', marginTop: '20px', marginBottom: '3px'}}>
          Select user
        </Box>
        <Select
          sx={{width: 160, maxWidth: 160}}
          size="sm"
          slotProps={{
            listbox: {
              sx: {
                maxHeight: 100,
                overflow: 'auto',
              }
            }
          }}
          value={user?.name || null}
          onChange={
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            (event: any) => selectUser(event?.target?.textContent)
          }
        >
          {
            userNames.map((name: string) => {
              return <Option key={name} value={name}>{name}</Option>;
            })
          }
        </Select>
      </>
    );
  };

  const renderPassword = () : JSX.Element => {
    if (isUserLoggedIn) {
      return (
        <div/>
      );
    }

    const checkPassword = (password: string) => {
      if (user && user.passwordMatch(password)) {
        setIsUserLoggedIn(true);
      }
    };

    return (
      <>
        <Box display="flex" sx={{alignItems: 'center', fontSize: '10pt', marginTop: '20px', marginBottom: '3px'}}>
          Password
        </Box>
        <Input
          type="password"
          size="sm"
          onChange={(event) => checkPassword(event.target.value)} />
      </>
    );
  };

  const renderLanguages = (): JSX.Element => {
    if (!isUserLoggedIn) {
      return (
        <div/>
      );
    }

    if (!language) {
      setLanguage(user?.languages[0]);
    }

    const selectLanguage = (lang: string) => {
      setLanguage(lang);
    };

    const takeQuiz = () => {
      // go to quiz page
      if (user) {
        const state = {userName: user.name, language, spreadsheetId: user.spreadsheetIdForLanguage(`${language}`)};
        localStorage.setItem('navState', JSON.stringify(state));
        navigate('/quiz', {state});
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkKeyPress = async (e: any) => {
      if (e.key === 'Enter') {
        takeQuiz();
        e.preventDefault();
      }
    };

    return (
      <div>
        <Box display="flex" sx={{alignItems: 'center', fontSize: '10pt', marginTop: '20px', marginBottom: '3px'}}>
          Select language
        </Box>
        <Box display="flex" alignItems="center">
          <Select
            sx={{width: 160, maxWidth: 160, marginTop: '10px'}}
            size="sm"
            slotProps={{
              listbox: {
                sx: {
                  maxHeight: 120,
                  overflow: 'auto'
                }
              }
            }}
            value={language}
            onChange={
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              (event: any) => selectLanguage(event?.target?.textContent)
            }
          >
            {
              user?.languages.map((lang: string) => {
                return <Option key={lang} value={lang}>{lang}</Option>;
              })
            }
          </Select>
          <Button
            sx={{
              color: 'white',
              marginTop: '10px',
              marginLeft: '4px',
              backgroundColor: '#048a83',
              '&:hover': {
                backgroundColor: '#0db2ab',
              }
            }}
            size="sm"
            onClick={takeQuiz}
            onKeyDown={checkKeyPress}
          >
            Go
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <div className="Home">
      <header className="Home-header">
        <img src='/images/logo512.png' className="Home-logo" alt="logo"/>
        {renderUserSelector()}
        {renderPassword()}
        {renderLanguages()}
      </header>
    </div>
  );
};

export default Home;
