import {sheetConfig} from './sheetConfig';
import Logger from '../utils/Logger';

// Google API items are loaded into window global by main index.html,
// since they count on browser loading behavior
// assume window.gapi and window.google are loaded by the time this is called,
// or we'd have to hook into the onload handlers or something on the main page.

export const TRACE_ENABLED = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trace = (message?: any, ...optionalParams: any[]): void => {
  if (TRACE_ENABLED) {
    const msg = `GSheet:${message}`;
    let objStr = '';
    if (optionalParams && optionalParams.length) {
      Logger.log(msg, optionalParams);
      objStr = JSON.stringify(optionalParams, null, 2);
    } else {
      Logger.log(msg);
    }

    const log = window.localStorage.getItem('lcLog') || '';
    window.localStorage.setItem('lcLog', `${log}\n${msg} <<${objStr}>>`);
    window.dispatchEvent(new Event('storage'));
  }
};

export type ICellValueType = string | number | boolean | Date;
export interface ICellColorStyle {
  rgbColor: {
    red: number,
    green: number,
    blue: number,
  },
}
interface ICellTextFormat {
  foregroundColorStyle?: ICellColorStyle,
  fontFamily?: string,
  fontSize?: number,
  bold?: boolean,
  italic?: boolean,
  strikethrough?: boolean,
  underline?: boolean,
  link?: {
    uri: string,
  },
}
interface ICellTextFormatRun {
  startIndex: number,
  format: ICellTextFormat,
}
export interface ICellFormat {
  textFormat?: ICellTextFormat,
  numberFormat?: {
    type: 'TEXT' | 'NUMBER' | 'PERCENT' | 'CURRENCY' | 'DATE' | 'TIME' | 'DATE_TIME' | 'SCIENTIFIC' | {pattern: string},
  },
  borders?: {
    [key in 'top' | 'bottom' | 'left' | 'right']?: {
      style?: 'DOTTED' | 'DASHED' | 'SOLID' | 'SOLID_MEDIUM' | 'SOLID_THICK' | 'NONE' | 'DOUBLE',
      colorStyle?: ICellColorStyle,
    }
  }
  backgroundColorStyle?: ICellColorStyle,
  verticalAlignment?: 'TOP' | 'MIDDLE' | 'BOTTOM',
  horizontalAlignment?: 'LEFT' | 'CENTER' | 'RIGHT',
  wrapStrategy?: 'OVERFLOW_CELL' | 'CLIP' | 'WRAP',
}
export interface IFormattedCellValue {
  value: ICellValueType,
  format?: ICellFormat,
  textFormatRuns?: ICellTextFormatRun[], // NB: different location than Google
}
export type ICellValue = IFormattedCellValue | ICellValueType;
export type IFormattableListValue = ICellValue[][];
export type IListValue = any[][]; // eslint-disable-line @typescript-eslint/no-explicit-any
export type ISpreadsheetData =  { [key: string]: IListValue };

export interface IUpdateCellValues {
  rowIndex: number,
  columnIndex: number,
  values: IListValue,
}

export interface AppendCellsBatchRequest {
  appendCells: {
    sheetId: number,
    rows: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
    fields: string,
  }
}

export interface UpdateCellsBatchRequest {
  updateCells: {
    rows: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
    start: {
      sheetId: number,
      rowIndex: number,
      columnIndex: number,
    },
    fields: string,
  },
}

export interface DeleteCellsBatchRequest {
  deleteDimension: {
    range: {
      sheetId: number,
      dimension: 'ROWS' | 'COLUMNS',
      startIndex: number,
      endIndex: number,
    }
  }
}

export interface ClearCellsBatchRequest {
  updateCells: {
    range: {
      sheetId: number,
    },
    fields: string,
  },
}

export interface FormatCellsBatchRequest {
  autoResizeDimensions: {
    dimensions: {
      sheetId: number,
      dimension: 'ROWS' | 'COLUMNS',
      startIndex: number,
      endIndex: number,
    }
  }
}

export interface AddSheetBatchRequest {
  addSheet: {
    properties: {
      title: string,
      index: number,
      sheetId: number,
    },
  },
}

export interface DeleteSheetBatchRequest {
  deleteSheet: {
    sheetId: number,
  },
}

export interface RenameSheetBatchRequest {
  updateSheetProperties: {
    properties: {
      sheetId: number,
      title: string,
    },
    fields: string,
  }
}

export type BatchRequest =
  | AppendCellsBatchRequest
  | UpdateCellsBatchRequest
  | DeleteCellsBatchRequest
  | ClearCellsBatchRequest
  | FormatCellsBatchRequest
  | AddSheetBatchRequest
  | DeleteSheetBatchRequest
  | RenameSheetBatchRequest
  ;

export type BatchResult = any; // eslint-disable-line @typescript-eslint/no-explicit-any


export class GSheet {
  private _spreadsheetMetaData: object = {};
  private _sheetNames: string[] = [];
  private _sheetNameIdMap: { [key: string]: number } = {};
  private _initialized = false;

  constructor(private _spreadsheetId: string) {
    if (!this._spreadsheetId) {
      throw new Error('missing spreadsheet ID');
    }
  }

  private _getSheetId(sheetName: string): number {
    const val = this._sheetNameIdMap[sheetName];

    // same as checking that it has a value/is defined
    // noinspection SuspiciousTypeOfGuard
    return typeof val === 'number' ? val : -1;
  }

  static hexColorToColorStyle(hexColor: string): ICellColorStyle {
    const match = hexColor.match(/^#([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/);
    if (!match) {
      throw new Error(`invalid color: ${hexColor}`);
    }

    const rgbValue = (hex: string): number => {
      return parseInt(hex, 16) / 255;
    };

    const [, redHex, greenHex, blueHex] = match;
    return {
      rgbColor: {
        red: rgbValue(redHex),
        green: rgbValue(greenHex),
        blue: rgbValue(blueHex),
      },
    };
  }

  static concatFormattedCells(...cells: IFormattedCellValue[]): IFormattedCellValue {
    let resultValue = '';
    const resultFormat: ICellFormat = {};
    const resultTextFormatRuns: ICellTextFormatRun[] = [];
    let startIndex = 0;

    // loop across given cells, concatenate assumed string values,
    // merge format directives besides textFormat,
    // and add textFormat from each to run array
    for (const cell of cells) {
      if (cell.value) {
        resultValue += cell.value;
      }

      // should never happen, but keep type checking happy
      if (!cell.format) {
        continue;
      }

      // separate textFormat from other props
      const {textFormat, ...rest} = cell.format;
      if (!textFormat) {
        continue;
      }

      // merge other props, last one wins
      Object.assign(resultFormat, rest);

      // add the textFormat run
      resultTextFormatRuns.push({
        startIndex,
        format: textFormat,
      });

      // update the index for the next guy, if any
      startIndex = resultValue.length;
    }

    // NB: the Google object has textFormatRuns at the parent level,
    // but we put it here, then it gets moved up in _formattableListValuesToBatchValues()
    return {
      value: resultValue,
      format: resultFormat,
      textFormatRuns: resultTextFormatRuns,
    };
  }

  async init() {
    if (!this._initialized) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
      if (!window.gapi.client) {
        await this._initGapiLoad();
      }

      // authorize user
      await this._initAuthorize();

      // uncomment to reset token for debugging/testing
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-ignore
      // window.gapi.client.setToken(null);

      // get spreadsheet metadata
      await this.getSpreadsheetInfo();

      this._initialized = true;
      trace('initd');
    }
  }

  private async _initGapiLoad() {
    trace('gapi load');
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    return new Promise((resolve, reject) => {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.gapi.load('client', () => {
          resolve(me._initGapiClient());
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  private async _initGapiClient() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await window.gapi.client.init({
      apiKey: sheetConfig.apiKey,
      discoveryDocs: [sheetConfig.discoveryDocs],
    });
  }

  private async _initAuthorize(force = false) {
    trace('authorizing');
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: sheetConfig.clientId,
        scope: sheetConfig.scopes.join(' '),
        access_type: 'offline',
        prompt: '',
        callback: async (resp: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          if (resp.error) {
            throw (resp);
          }

          // ready for business
          trace('authorized, resolved', resp);
          resolve(resp);
        },
      });

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const token = window.gapi.client.getToken();
        trace('token', token);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (force || token === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
          trace('token confirm');
          tokenClient.requestAccessToken({
            access_type: 'offline',
            prompt: ''
          });
        } else {
          // Skip display of account chooser and consent dialog for an existing session.
          trace('token skip confirm', token);
          resolve(token);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async _networkCall<T>(label: string, fn: () => Promise<T>): Promise<T> {
    const MAX_ATTEMPTS = 5;
    let attempt = 1;
    let error = null;

    const sleep = (msec: number): Promise<void> => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, msec);
      });
    };

    while (attempt <= MAX_ATTEMPTS) {
      try {
        const result : T = await fn();
        if (attempt > 1) {
          Logger.log(`_networkCall: ${label} succeeded (${attempt}/${MAX_ATTEMPTS})`);
          error = null;
        }
        return result;
      } catch (err: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (err?.result?.error?.code === 401) {
          Logger.log(`_networkCall: ${label} AUTH FAILED, reauthorizing and retrying`, err.result.error);
          await this._initAuthorize(true);
          // only retry once
          if (attempt < MAX_ATTEMPTS) {
            attempt = MAX_ATTEMPTS;
          } else {
            attempt++;
          }
          continue;
        }

        // if a general network error, backoff and retry
        if (err?.result?.error?.code === -1) {
          attempt++;
          // exp backoff, but don't sleep if retries exhausted
          if (attempt <= MAX_ATTEMPTS) {
            const waitMSec = 250 * (attempt ** 2);
            Logger.log(`_networkCall: ${label} generic network error, waiting ${waitMSec}ms and then retrying (${attempt}/${MAX_ATTEMPTS})`, err.result.error);
            await sleep(waitMSec);
          }
          error = err;
          continue;
        }

        // not an auth error or basic network error, simply propagate without retry
        Logger.error(`_networkCall: ${label} unknown network error`, err.result.error);
        error = err;
        break;
      }
    }

    trace(`_networkCall: ${label} unexpected error, retries exhausted`, error);
    throw error;
  }

  async getSpreadsheetInfo(metaData?: object): Promise<void> {
    trace('get spreadsheet metadata');

    const impl = async () => {
      if (metaData) {
        this._spreadsheetMetaData = metaData;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = await window.gapi.client.sheets.spreadsheets.get({
          spreadsheetId: this._spreadsheetId,
        });

        // save metadata
        this._spreadsheetMetaData = response.result;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._sheetNames = this._spreadsheetMetaData.sheets.map((s:any) => s.properties.title);

      // create new sheet name to ID map
      this._sheetNameIdMap = {};

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._spreadsheetMetaData.sheets.forEach((s: any) => this._sheetNameIdMap[s.properties.title] = s.properties.sheetId);
      trace('sheetNameIdMap', this._sheetNameIdMap);
    };

    try {
      return await this._networkCall<void>('getSpreadsheetInfo', impl);
    } catch (err) {
      Logger.log('Error in getSpreadsheetInfo()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }

  private _formattableListValuesToBatchValues(formattableListValues: IFormattableListValue) {
    const cellValue = (cv: ICellValue) => {
      let label = 'stringValue';
      const userEnteredFormat = {
        'verticalAlignment': 'top',
      };
      let primitiveValue;
      let textFormatRuns;
      if (typeof cv === 'object' && (<IFormattedCellValue>cv).value !== undefined) {
        const fcv = <IFormattedCellValue>cv;
        primitiveValue = fcv.value;
        Object.assign(userEnteredFormat, fcv.format);
        textFormatRuns = fcv.textFormatRuns;
      } else {
        primitiveValue = cv;
      }
      let value: ICellValueType = `${primitiveValue}`;
      if (typeof primitiveValue === 'number') {
        label = 'numberValue';
        value = primitiveValue;
      } else if (typeof primitiveValue === 'boolean') {
        label = 'boolValue';
        value = primitiveValue;
      } else if (primitiveValue === undefined) {
        value = '';
      }

      return {
        'userEnteredValue': {
          [label]: value,
        },
        userEnteredFormat,
        textFormatRuns,
      };
    };

    return formattableListValues.map(row => ({ values: row.map(cv => cellValue(cv)) }));
  }


  /**
   * batch fetch data from given sheets in spreadsheet
   * @param sheetNames
   */
  async getSpreadsheetData(sheetNames: string[]): Promise<ISpreadsheetData> {
    await this.init();

    const impl = async () => {
      const ranges = sheetNames.map(n => `${n}!A:Z`);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await window.gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId: this._spreadsheetId,
        ranges,
        majorDimension: 'ROWS',
        valueRenderOption: 'UNFORMATTED_VALUE',
      });

      const result = response.result;
      const spreadsheetData: ISpreadsheetData = {};
      for (const vr of result.valueRanges) {
        const key = vr.range.replace(/!.*$/, '').replace(/^'(.*)'$/, '$1');
        spreadsheetData[key] = vr.values;
      }

      trace('getSheetData result', {result, spreadsheetData});
      return spreadsheetData;
    };

    try {
      return await this._networkCall<ISpreadsheetData>('getSpreadsheetData', impl);
    } catch (err) {
      Logger.log('Error in getSheetData()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }

  /**
   * submit batchUpdate of given requests
   * ask for resultant spreadsheet, update local cache
   * return result
   * @param requests
   */
  async batchUpdate(requests: BatchRequest[]): Promise<BatchResult> {
    const impl = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await window.gapi.client.sheets.spreadsheets.batchUpdate({
        spreadsheetId: this._spreadsheetId,
        requests,
        includeSpreadsheetInResponse: true,
      });

      const result = response.result;
      trace('batchUpdate result', result);

      // refresh sheet metadata, since we just change the sheets
      if (result.updatedSpreadsheet) {
        await this.getSpreadsheetInfo(result.updatedSpreadsheet);
      }

      return result;
    };

    try {
      return await this._networkCall<void>('batchUpdate', impl);
    } catch (err) {
      Logger.log('Error in batchUpdate()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }

  /**
   * build requests to move row from source, appending to dest, using values given
   *    (single batch call to keep call count down, so provide values from previous fetch
   *     rather than do another lookup which would be another call)
   * @param sourceSheetName
   * @param sourceRowIndex
   * @param destSheetName
   * @param values
   */
  async buildMoveRow(sourceSheetName: string, sourceRowIndex: number, destSheetName: string, values: IListValue): Promise<BatchRequest[]> {
    await this.init();

    const sheetId = this._getSheetId(destSheetName);
    if (sheetId === -1) {
      throw new Error(`moveRowRequests(): invalid dest sheet ${destSheetName}`);
    }

    const srcSheetId = this._getSheetId(sourceSheetName);
    if (srcSheetId === -1) {
      throw new Error(`moveRowRequests(): invalid source sheet ${sourceSheetName}`);
    }

    // create append to destination request
    const appendRequest: AppendCellsBatchRequest = {
      appendCells: {
        sheetId,
        rows: this._formattableListValuesToBatchValues(values),
        fields: '*',
      },
    };

    // create delete from source request
    const deleteRequest: DeleteCellsBatchRequest = {
      deleteDimension: {
        range: {
          sheetId: srcSheetId,
          dimension: 'ROWS',
          // offset index values by -1
          // endIndex is exclusive, so we would add the 1 back, so we leave it
          startIndex: sourceRowIndex - 1,
          endIndex: sourceRowIndex,
        },
      },
    };

    return [
      appendRequest,
      deleteRequest,
    ];
  }

  /**
   * move rows from source to dest
   * if new source sheet name given, delete from source sheet and rename source sheet to that new name
   * if not given, delete the source sheet
   * @param sourceSheetName
   * @param destSheetName
   * @param values
   * @param newSourceSheetName
   */
  async moveRowsFromSheetRequests(sourceSheetName: string, destSheetName: string, values: IListValue, newSourceSheetName: string | null): Promise<BatchRequest[]> {
    await this.init();

    const sheetId = this._getSheetId(destSheetName);
    if (sheetId === -1) {
      throw new Error(`moveRowsFromSheet(): invalid dest sheet ${destSheetName}`);
    }

    const srcSheetId = this._getSheetId(sourceSheetName);
    if (srcSheetId === -1) {
      throw new Error(`moveRowsFromSheet(): invalid source sheet ${sourceSheetName}`);
    }

    // create append to destination request
    const appendRequest: AppendCellsBatchRequest = {
      appendCells: {
        sheetId,
        rows: this._formattableListValuesToBatchValues(values || [[]]),
        fields: '*',
      },
    };

    const requests: BatchRequest[] = [appendRequest];

    if (newSourceSheetName) {
      // clear all the data on the sheet
      const clearRequest: ClearCellsBatchRequest = {
        updateCells: {
          range: {
            sheetId: srcSheetId,
          },
          fields: '*',
        },
      };
      requests.push(clearRequest);

      // create rename sheet request
      const renameRequest: RenameSheetBatchRequest = {
        updateSheetProperties: {
          properties: {
            sheetId: srcSheetId,
            title: newSourceSheetName,
          },
          fields: 'title',
        },
      };
      requests.push(renameRequest);
    } else {
      // create delete sheet request
      const deleteRequest: DeleteSheetBatchRequest = {
        deleteSheet: {
          sheetId: srcSheetId,
        },
      };
      requests.push(deleteRequest);
    }

    return requests;
  }


  /**
   * move rows from source to dest
   * if new source sheet name given, delete from source sheet and rename source sheet to that new name
   * if not given, delete the source sheet
   * @param sourceSheetName
   * @param destSheetName
   * @param values
   * @param newSourceSheetName
   */
  async moveRowsFromSheet(sourceSheetName: string, destSheetName: string, values: IListValue, newSourceSheetName: string | null): Promise<string[]> {
    try {
      const requests: BatchRequest[] = await this.moveRowsFromSheetRequests(sourceSheetName, destSheetName, values, newSourceSheetName);
      await this.batchUpdate(requests);
      trace('moveRowsFromSheet complete');
      return this._sheetNames;
    } catch (err) {
      Logger.log('Error in moveRowsFromSheet()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }


  /**
   * insert new sheet of given name at given index, add given values
   * @param sheetName
   * @param sheetIndex
   * @param values
   */
  async addSheetRequests(sheetName: string, sheetIndex: number, values: IListValue): Promise<BatchRequest[]> {
    await this.init();

    // generate a new sheet id from current time milliseconds, capped at maxint32
    const sheetId: number = new Date().getTime() % 2147483648;

    // create append to destination request
    const addSheetRequest: AddSheetBatchRequest = {
      addSheet: {
        properties: {
          title: sheetName,
          index: sheetIndex,
          sheetId,
        },
      },
    };

    // create append to destination request
    const appendRequest: AppendCellsBatchRequest = {
      appendCells: {
        sheetId,
        rows: this._formattableListValuesToBatchValues(values),
        fields: '*',
      },
    };

    return [
      addSheetRequest,
      appendRequest,
    ];
  }


  /**
   * insert new sheet of given name at given index, add given values
   * @param sheetName
   * @param sheetIndex
   * @param values
   */
  async addSheet(sheetName: string, sheetIndex: number, values: IListValue): Promise<string[]> {
    try {
      const requests: BatchRequest[] = await this.addSheetRequests(sheetName, sheetIndex, values);
      await this.batchUpdate(requests);
      trace('addSheet complete');
      return this._sheetNames;
    } catch (err) {
      Logger.log('Error in addSheet()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }


  /**
   * replace sheet contents with the given values
   * @param sheetName
   * @param values
   * @param newSheetName
   */
  async buildReplaceSheet(sheetName: string, values: IListValue, newSheetName?: string): Promise<BatchRequest[]> {
    await this.init();

    const sheetId = this._getSheetId(sheetName);
    if (sheetId === -1) {
      throw new Error(`replaceSheet(): invalid sheet ${sheetName}`);
    }

    const requests = [];

    // clear the destination sheet
    const clearRequest: ClearCellsBatchRequest = {
      updateCells: {
        range: {
          sheetId,
        },
        fields: '*',
      },
    };
    requests.push(clearRequest);

    // create append to destination request
    const appendRequest: AppendCellsBatchRequest = {
      appendCells: {
        sheetId,
        rows: this._formattableListValuesToBatchValues(values),
        fields: '*',
      },
    };
    requests.push(appendRequest);

    // format cells
    const formatRequest: FormatCellsBatchRequest = {
      autoResizeDimensions: {
        dimensions: {
          sheetId,
          dimension: 'COLUMNS',
          startIndex: 0,
          endIndex: 5,
        }
      }
    };
    requests.push(formatRequest);

    // rename sheet, if asked
    if (newSheetName) {
      const renameRequest: RenameSheetBatchRequest = {
        updateSheetProperties: {
          properties: {
            sheetId,
            title: newSheetName,
          },
          fields: 'title',
        }
      };
      requests.push(renameRequest);
    }

    return requests;
  }


  /**
   * update cells in a sheet starting at a given coordinate, optionally renaming sheet
   * @param sheetName
   * @param cellValues
   * @param newSheetName
   */
  async updateSheetRequests(sheetName: string, cellValues: IUpdateCellValues[], newSheetName?: string): Promise<BatchRequest[]> {
    await this.init();

    const sheetId = this._getSheetId(sheetName);
    if (sheetId === -1) {
      throw new Error(`updateSheet(): invalid sheet ${sheetName}`);
    }

    const requests = [];

    // loop across the cellValue/coordinate sets
    for (const cellValue of cellValues) {
      const {rowIndex, columnIndex} = cellValue;

      // update the cells
      const updateRequest: UpdateCellsBatchRequest = {
        updateCells: {
          rows: this._formattableListValuesToBatchValues(cellValue.values),
          start: {
            sheetId,
            rowIndex,
            columnIndex,
          },
          fields: '*',
        },
      };
      requests.push(updateRequest);

      // format cells
      const formatRequest: FormatCellsBatchRequest = {
        autoResizeDimensions: {
          dimensions: {
            sheetId,
            dimension: 'COLUMNS',
            startIndex: columnIndex,
            endIndex: columnIndex + 1,
          }
        }
      };
      requests.push(formatRequest);
    }

    // rename sheet, if asked
    if (newSheetName) {
      const renameRequest: RenameSheetBatchRequest = {
        updateSheetProperties: {
          properties: {
            sheetId,
            title: newSheetName,
          },
          fields: 'title',
        }
      };
      requests.push(renameRequest);
    }

    return requests;
  }


  /**
   * update cells in a sheet starting at a given coordinate, optionally renaming sheet
   * @param sheetName
   * @param cellValues
   * @param newSheetName
   */
  async updateSheet(sheetName: string, cellValues: IUpdateCellValues[], newSheetName?: string): Promise<void> {
    try {
      const requests: BatchRequest[] = await this.updateSheetRequests(sheetName, cellValues, newSheetName);
      await this.batchUpdate(requests);
      trace('updateSheet complete');
    } catch (err) {
      Logger.log('Error in updateSheet()', err);
      throw new Error(`${JSON.stringify(err, null, 2)}`);
    }
  }


  /**
   * return the sheet (tab) names
   */
  async getSheetNames(): Promise<string[]> {
    await this.init();

    return this._sheetNames;
  }
}
