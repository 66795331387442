import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import Checkbox from '@mui/joy/Checkbox';
import { Button } from '@mui/joy';
// find icons at https://mui.com/material-ui/material-icons/?query=open
import Done from '@mui/icons-material/Done';
import Warning from '@mui/icons-material/Warning';
import Dangerous from '@mui/icons-material/Dangerous';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useLocation } from 'react-router-dom';
import {
  IQuizEntry,
  IQuizEntryDuplicate,
  IQuizProblemAnswer,
  IQuizResponses,
  IQuizSet,
  IQuizStateSummary,
  IQuizStats,
  QuizManager,
  QuizScope
} from '../features/QuizManager';
import LinearProgress from '@mui/joy/LinearProgress';
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Oval } from 'react-loader-spinner';
import { QuizItemState } from '../models/QuizItem';
import { TRACE_ENABLED } from '../models/GSheet';
import strftime from 'strftime';
import Logger from '../utils/Logger';

type QuizInputState = 'awaiting' | 'ready' | 'checking' | 'done';

// requires GSheet tracing to populate log, and this to be set to true to enable display of log here
const QUIZ_TRACE_ENABLED = TRACE_ENABLED && false;

const Quiz = () => {
  const location = useLocation();
  const savedNavState = JSON.parse(localStorage.getItem('navState') || '{}');
  const state = Object.assign({}, location?.state || savedNavState);
  const textInput = useRef(null);

  const [prevSession, setPrevSession] = useState<string>();
  const [quizScope, setQuizScope] = useState<QuizScope>();
  const [quizManager, setQuizManager] = useState<QuizManager>();
  const [quizSet, setQuizSet] = useState<IQuizSet>({} as IQuizSet);
  const [quizSetEntryIndex, setQuizSetEntryIndex] = useState<number>(-1);
  const [duplicates, setDuplicates] = useState<IQuizEntryDuplicate[]>([]);
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [responsesEntered, setResponsesEntered] = useState<IQuizResponses>([]);
  const [stats, setStats] = useState<IQuizStats>({} as IQuizStats);
  const [quizInputState, setQuizInputState] = useState<QuizInputState>('awaiting');
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [log, setLog] = useState<string | null>(null);
  const [problemAnswers, setProblemAnswers] = useState<IQuizProblemAnswer[]>([]);
  const [processingPending, setProcessingPending] = useState<boolean>(false);
  const [processingError, setProcessingError] = useState<Error>();
  const [hideWaitSeconds, setHideWaitSeconds] = useState<number>(0);

  const setFocus = () => {
    const current: HTMLElement | null = textInput.current;
    if (current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      current.getElementsByTagName('input')[0].focus();
    }
  };

  const loadInitialSessionState = async () => {
    const qm = new QuizManager(state.userName, state.language, state.spreadsheetId);
    setQuizManager(qm);

    // check for duplicates
    const dups = await qm.duplicates();
    setDuplicates(dups);

    // see if we have an open session
    const prev = await qm.openSessionSheetName();
    if (prev) {
      setPrevSession(prev);
    } else {
      setPrevSession('none found');
    }
  };

  const loadQuizSet = async () => {
    let qm = quizManager;
    if (!qm) {
      qm = new QuizManager(state.userName, state.language, state.spreadsheetId);
      setQuizManager(qm);
    }

    if (quizScope === 'resume') {
      // load quiz data from existing session
      const sessionState = await qm.resumeQuiz();
      const quizSetData: IQuizSet = sessionState.quizSet;
      setQuizSet(quizSetData);
      const quizStats: IQuizStats = sessionState.stats;
      setStats(quizStats);

      // saved stats are the last one completed, so start with next one
      setQuizSetEntryIndex(quizStats.currentIndex + 1);
      if (quizStats.problemAnswers?.length) {
        const pa = quizStats.problemAnswers.map(p => p.quizProblemAnswer);
        if (pa?.length) {
          setProblemAnswers(pa);
        }
      }
    } else {
      // abandon any open session
      await qm.abandonOpenSession();

      // load quiz data, restricting to scope as directed
      const quizSetData = await qm.quizSet(quizScope);
      setQuizSet(quizSetData);
      setQuizSetEntryIndex(0);
      setProblemAnswers([]);
    }
  };

  // on mount
  useEffect(() => {
    const handleStorageEvent = () => {
      setLog(window.localStorage.getItem('lcLog') || null);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // window.gapi?.client?.setToken(null);
    if (QUIZ_TRACE_ENABLED) {
      window.localStorage.removeItem('lcLog');
      window.addEventListener('storage', handleStorageEvent);
    }

    // globally handle uncaught promise errors and the like
    const handleRejectionEvent = (event: PromiseRejectionEvent) => {
      event.promise.catch((error) => {
        setProcessingPending(false);
        setProcessingError(error);
      });
      event.preventDefault();
      event.stopPropagation();
    };
    window.addEventListener('unhandledrejection', handleRejectionEvent);

    // noinspection JSIgnoredPromiseFromCall
    loadInitialSessionState();

    // clear handlers on unmount
    return () => {
      window.removeEventListener('unhandledrejection', handleRejectionEvent);
      if (QUIZ_TRACE_ENABLED) {
        document.removeEventListener('storage', handleStorageEvent);
      }
    };
  }, []);

  // when scope selected, load quiz
  useEffect(() => {
    if (quizScope) {
      // noinspection JSIgnoredPromiseFromCall
      loadQuizSet();
    }
  }, [quizScope]);

  // on next quiz set item
  useEffect(() => {
    setFocus();
  }, [quizSetEntryIndex]);

  const nextQuizEntry = async () => {
    // reset response states
    setResponsesEntered([]);
    setIsSubmitted(false);
    setIsRevealed(false);
    setQuizInputState('awaiting');
    setHideWaitSeconds(0);

    // reset text boxes, clearing values and re-enabling
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.document.querySelectorAll('.response-input input')
      .forEach(e => {
        (e as any).value = ''; // eslint-disable-line @typescript-eslint/no-explicit-any
        (e as any).readOnly = false; // eslint-disable-line @typescript-eslint/no-explicit-any
      });

    // when quiz set is exhausted, session is complete
    if (quizSet.areAllRoundsComplete) {
      setQuizSetEntryIndex(-1);
      setIsComplete(true);

      // refresh duplicates at end (forced by final gradeQuiz call, this just updates our copy)
      if (quizManager) {
        const dups = await quizManager.duplicates();
        setDuplicates(dups);
      }

      return;
    } else if (quizManager && quizSet.isFocusMode && quizSet.isRoundComplete) {
      const upd: {newQuizSet: IQuizSet, newStats: IQuizStats} = quizManager.resetFocusRound(quizSet, stats);
      setQuizSet(upd.newQuizSet);
      setStats(upd.newStats);
      setQuizSetEntryIndex(0);
      return;
    }

    // advance to next quiz entry
    setQuizSetEntryIndex(quizSetEntryIndex + 1);
    return;
  };

  const gradeQuizCompletionCallback = (success: boolean, err: null | unknown): void => {
    setProcessingPending(false);
    if (!success) {
      // use uncaught rejection handler for errors
      // noinspection JSIgnoredPromiseFromCall
      Promise.reject(err);
    }
  };

  const checkResponses = async () => {
    if (quizManager && quizSetEntryIndex >= 0) {
      // disable check button
      setQuizInputState('checking');

      // note the attempt and save the responses
      const currentEntry = quizSet.setEntries[quizSetEntryIndex];
      if (typeof currentEntry === 'undefined') {
        throw new Error(`invalid entry index: ${quizSetEntryIndex}`);
      }
      currentEntry.tries++;
      if (typeof currentEntry.responses === 'undefined') {
        currentEntry.responses = [];
      }
      currentEntry.responses.push(responsesEntered);

      // grade responses, which will update sheet(s) as appropriate
      // actual sheet updates run async, and on error call the callback
      setProcessingPending(true);
      const quizStats: IQuizStats = await quizManager.gradeQuiz(quizSet, quizSetEntryIndex, responsesEntered, problemAnswers, gradeQuizCompletionCallback, stats?.prevQuizStats);

      // set states from stats after grading
      if (quizStats.areAllCorrect) {
        setQuizInputState('done');
      } else {
        setQuizInputState('awaiting');
      }
      setStats(quizStats);
    }

    // note as submitted to render check marks correctly
    setIsSubmitted(true);
  };

  const renderInputBoxes = (): JSX.Element => {
    const saveResponse = (index: number, response: string) => {
      // save response by field index
      const newResponsesEntered: IQuizResponses = responsesEntered.concat([]);
      newResponsesEntered[index] = response.trim();
      setResponsesEntered(newResponsesEntered);

      // clear submitted if still typing in responses,
      // enable submit button only after input (new or change)
      setIsSubmitted(false);
      if (quizInputState === 'awaiting' && response) {
        setQuizInputState('ready');
      }
    };

    const renderResponseIndicator = (index: number): JSX.Element => {
      if (isSubmitted && quizSetEntryIndex >= 0) {
        const itemEntered = responsesEntered[index];
        const itemIsCorrect = quizSet.setEntries[quizSetEntryIndex].answers.includes(itemEntered);
        const itemIsDuplicated = responsesEntered.filter(resp => resp === itemEntered).length > 1;
        if (itemIsDuplicated) {
          return (
            <Box display='flex'>
              <Warning sx={{
                color: '#ff9900',
                marginRight: '6pt'
              }}/>
            </Box>
          );
        } else if (itemIsCorrect) {
          return (
            <Box display='flex'>
              <Done sx={{
                color: '#00ff00',
                marginRight: '6pt'
              }}/>
            </Box>
          );
        } else {
          return (
            <Box display='flex'>
              <Dangerous sx={{
                color: '#ff0000',
                marginRight: '6pt'
              }}/>
            </Box>
          );
        }
      }

      return (<Box display='flex' sx={{
        marginTop: '16pt',
        width: '28px'
      }}/>);
    };

    const renderTextField = (index: number): JSX.Element => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const checkKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
          if (quizInputState === 'ready' && !processingPending) {
            await checkResponses();
            e.preventDefault();
          } else if (quizInputState === 'done') {
            await nextQuizEntry();
            e.preventDefault();
          }
        }
      };

      return (
        <Box key={`answer_${index}`} display='flex' justifyItems='start' alignItems='center'
          sx={{ marginTop: '8pt' }}>
          {renderResponseIndicator(index)}
          <FormControl size="lg">
            <Input
              className={'response-input'}
              {...(index === 0 ? { ref: textInput } : {})}
              onChange={(event) => saveResponse(index, event.target.value)}
              onKeyDown={checkKeyPress}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}/>
          </FormControl>
        </Box>
      );
    };

    const renderAnswer = (answer: string, index: number): JSX.Element => {
      const noteProblem = (answerIndex: number, checked: boolean) => {
        // add or remove problem answers
        if (checked) {
          const newProblemAnswers: IQuizProblemAnswer[] = problemAnswers;
          newProblemAnswers.push({
            quizSetEntryIndex,
            answerIndex,
          });
          setProblemAnswers(newProblemAnswers);
        } else {
          setProblemAnswers(problemAnswers.filter(p => !(p.quizSetEntryIndex === quizSetEntryIndex && p.answerIndex === answerIndex)));
        }
      };

      return (
        <Box key={`answer_${index}`} sx={{marginTop: '16px'}}>
          <Box display='inline-flex'>
            <Checkbox
              key={`problem_${quizSetEntryIndex}_${index}`}
              size="sm"
              sx={{marginRight: '10px', marginTop: '4px'}}
              onChange={(event) => noteProblem(index, event.target.checked)}
            />
            <Box sx={{fontSize: '16pt', marginBottom: '10px'}}>
              {answer}
            </Box>
          </Box>
        </Box>
      );
    };

    const renderResponseBoxes  = (hide = false): JSX.Element => {
      const display = {display: hide ? 'none' : 'block'};
      return (
        <Box sx={{flexDirection: 'column', width: '246px', display}}>
          {quizSet.setEntries[quizSetEntryIndex].answers.map((_, index) => renderTextField(index))}
        </Box>
      );
    };

    const renderAnswers = (): JSX.Element => {
      if (quizSetEntryIndex >= 0) {
        return (
          <Box sx={{flexDirection: 'column', width: '246px', marginTop: '5px'}}>
            <Box sx={{
              marginTop: '20px',
              marginLeft: '40px'
            }}>
              {quizSet.setEntries[quizSetEntryIndex].answers.map((answer, index) => renderAnswer(answer, index))}
            </Box>
          </Box>
        );
      }

      return (
        <div/>
      );
    };

    if (quizSetEntryIndex >= 0) {
      if (isRevealed) {
        return (
          <>
            {renderResponseBoxes(true)}
            {renderAnswers()}
          </>
        );
      }

      return (
        <>
          {renderResponseBoxes()}
        </>
      );
    }

    return (
      <div/>
    );
  };

  const renderButtons = (): JSX.Element => {
    const reveal = () => {
      setIsRevealed(!isRevealed);

      // isRevealed is still old value since setIsRevealed() hasn't completed yet, so logic reversed.
      if (!isRevealed) {
        const currentEntry = quizSet.setEntries[quizSetEntryIndex];
        currentEntry.responses.push(['<revealed>']);
        currentEntry.isRevealed = true;

        // if revealing, disable button to hide and go back for a longer and longer time
        // each time, to reduce flip-and-type

        // determine number of times revealed so far
        const revealTimes = currentEntry.responses.filter(r => r.length === 1 && r[0] === '<revealed>').length;

        // exponentially longer wait
        let ws = Math.min(30, (2 ** (revealTimes - 1)) - 1);
        setHideWaitSeconds(ws);
        const elem: HTMLButtonElement | null = window.document.querySelector('#revealButton');

        if (ws > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.document.querySelector('#revealButton').disabled = true;

          const countdownInterval = setInterval(() => {
            if (ws <= 0) {
              if (elem) {
                elem.disabled = false;
              }
              clearInterval(countdownInterval);
            } else {
              ws--;
            }
            setHideWaitSeconds(ws);
          }, 1000);
        }
      }
    };

    if (quizInputState === 'done') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.document.querySelectorAll('.response-input input').forEach(e => e.readOnly = true);

      const clickNext = async () => {
        await nextQuizEntry();
      };

      return (
        <Box display='flex' style={{
          marginLeft: '6pt',
          width: '160px'
        }}>
          <Button
            sx={{
              color: 'white',
              marginLeft: '28px',
              backgroundColor: '#048a83',
              '&:hover': {
                backgroundColor: '#0db2ab',
              }
            }}
            size="sm"
            onClick={clickNext}
          >
            Next
          </Button>
        </Box>
      );
    }

    const checkResponsesClick = async () => {
      await checkResponses();
    };

    const showWaitSeconds = (): JSX.Element => {
      if (hideWaitSeconds > 0) {
        return (
          <Box sx={{
            color: '#ccdcdc',
            marginTop: '6px',
            marginLeft: '6px',
            fontSize: '12pt',
          }}>
            {hideWaitSeconds}s...
          </Box>
        );
      }

      return (<div/>);
    };

    const showPending = (): JSX.Element => {
      if (processingPending) {
        return (
          <HourglassEmptyIcon sx={{
            color: '#595d5d',
            marginTop: '5px',
            marginLeft: '5px',
            fontSize: '16pt',
          }}/>
        );
      }

      return (<div/>);
    };

    return (
      <Box display='flex' style={{
        marginLeft: '6pt',
        width: '160px'
      }}>
        <Button
          sx={{
            color: 'white',
            marginLeft: '8px',
            backgroundColor: '#048a83',
            '&:hover': {
              backgroundColor: '#0db2ab',
            }
          }}
          size="sm"
          disabled={quizInputState === 'awaiting' || quizInputState === 'checking' || processingPending || isRevealed}
          onClick={checkResponsesClick}
        >
          {quizInputState === 'checking' ? 'Checking...' : 'Check'}
        </Button>
        <Button
          sx={{
            color: '#107570',
            marginLeft: '8px',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: '#c2f8f4',
            },
            '&:disabled': {
              backgroundColor: 'grey',
            },
            width: '80px',
          }}
          id='revealButton'
          size="sm"
          onClick={reveal}
        >
          {isRevealed ? 'Hide' : 'Reveal'}
        </Button>
        {showWaitSeconds()}
        {showPending()}
      </Box>
    );
  };

  const renderEmpty = (): JSX.Element => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box display="inline-flex" sx={{ marginTop: '12px', }}>
          <Box display="flex" sx={{marginTop: '6px', marginLeft: '20px'}}>No Entries Found</Box>
          <Button
            sx={{
              color: 'white',
              marginLeft: '20px',
              marginRight: '20px',
              backgroundColor: '#048a83',
              '&:hover': {
                backgroundColor: '#0db2ab',
              }
            }}
            size="sm"
            onClick={takeAgain}
          >
              Select Again
          </Button>
        </Box>
      </Box>
    );
  };

  const renderQuiz = (): JSX.Element => {
    const renderNew = (): JSX.Element => {
      if (quizSet.setEntries[quizSetEntryIndex]?.state === 'new') {
        return (
          <Box display='inline-flex'>
            <NewReleasesIcon sx={{
              color: '#0db2ab',
              fontSize: '32pt',
              marginTop: '37px',
              marginLeft: '8pt',
            }}/>
          </Box>
        );
      }

      return (<div/>);
    };

    return (
      <Box sx={{ width: '100%' }}>
        <Box display="flex" justifyContent="center">
          <Box display="inline-flex" sx={{
            marginTop: '48px',
            marginRight: '20px'
          }}>
            <Box display="flex" alignItems="center" sx={{flexDirection: 'column'}}>
              <Box display="inline-flex">
                {quizSetEntryIndex + 1} / {quizSet.setEntries.length}
              </Box>
              <Box display="flex" sx={{color: 'grey', fontSize: '11pt'}}>
                {quizScope === 'newOnly' ? 'New Only' : (quizScope == 'focusMode' ? `Focus Mode (${quizSet.round})` : '')}
              </Box>
            </Box>
          </Box>
          <Box display="inline-flex" sx={{
            textAlign: 'center',
            fontSize: '32pt',
            color: 'white',
            border: 'solid 2pt grey',
            padding: '20pt 30pt 20pt 30pt'
          }}>
            {quizSet.setEntries[quizSetEntryIndex]?.quizEntry}
          </Box>
          {renderNew()}
        </Box>
        <Box display="flex" justifyContent="center" sx={{
          alignItems: 'baseline',
          marginTop: '8pt'
        }}>
          {renderInputBoxes()}
          {renderButtons()}
        </Box>
      </Box>
    );
  };

  // noinspection JSIgnoredPromiseFromCall
  const takeAgain = () => {
    setQuizManager(new QuizManager(state.userName, state.language, state.spreadsheetId));
    setQuizSet({} as IQuizSet);
    setQuizSetEntryIndex(-1);
    setDuplicates([]);
    setIsRevealed(false);
    setIsSubmitted(false);
    setResponsesEntered([]);
    setProblemAnswers([]);
    setStats({} as IQuizStats);
    setQuizInputState('awaiting');
    setIsComplete(false);
    setLog(null);
    setPrevSession(undefined);
    setQuizScope(undefined);
    setProcessingPending(false);
    setProcessingError(undefined);

    // noinspection JSIgnoredPromiseFromCall
    loadInitialSessionState();

    // with scope cleared, should redraw to present scope-choice page
  };


  const renderSummary = (): JSX.Element => {
    const renderTableHeaderCell = (s: QuizItemState, header?: string): JSX.Element => {
      return (
        <th key={`th_${s}`} style={{
          textAlign: 'center',
          height: '35px',
          fontWeight: 'bold',
          fontSize: '12pt',
          textTransform: 'capitalize',
          border: 'solid 1px grey',
        }}>
          {header || s}
        </th>
      );
    };

    const renderTableCell = (s: QuizItemState, quizStats: IQuizStats, includeRoundSummary = false): JSX.Element => {
      const summaries = quizStats?.summary || {};
      const summary = summaries[s];
      if (!summary?.entries) {
        Logger.log(`no summary entries for ${s}`, {stats, summaries});
        return (<td key={`td_${s}`} />);
      }

      const {
        entries,
        correctCount,
        correctPercentage,
        missedEntries,
        missedCount,
        missedPercentage,
        revealedCount,
      } = summary as IQuizStateSummary;
      const entryCount = entries.length;
      const perfectedCount = stats.perfectedCount;

      const renderPerfected = (): JSX.Element => {
        if (s === 'mastered' && perfectedCount > 0) {
          return (
            <Box sx={{
              fontSize: '9pt',
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#048a83',
              marginLeft: '40px'
            }}>{perfectedCount} perfected!</Box>
          );
        }

        return (<div/>);
      };

      const renderRoundSummary = (): JSX.Element => {
        if (includeRoundSummary) {
          return (
            <Box sx={{ marginLeft: '10px' }}>
              {renderSummaryStats(quizStats)}
            </Box>
          );
        }

        return (<div/>);
      };

      return (
        <td key={`td_${s}`} style={{
          border: 'solid 1px grey',
          verticalAlign: 'top',
          fontSize: '10pt',
          minWidth: '190px',
        }}>
          <Box sx={{ marginLeft: '10px' }}>{correctPercentage || 0}% Correct
            ({correctCount}/{entryCount})</Box>
          <p/>
          <Box sx={{ marginLeft: '10px' }}>{missedPercentage || 0}% Missed
            ({missedCount}/{entryCount})</Box>
          <Box sx={{
            fontSize: '9pt',
            fontStyle: 'italic',
            marginLeft: '40px'
          }}>{revealedCount} revealed</Box>
          {renderPerfected()}
          <Box>
            <ul>
              {
                missedEntries.map((e, i) => {
                  return (
                    <li key={`stat_${s}_${i}`}
                      style={{ fontStyle: e.isRevealed ? 'italic' : 'normal' }}>{e.quizEntry} ({e.tries})</li>
                  );
                })
              }
            </ul>
          </Box>
          {renderRoundSummary()}
        </td>
      );
    };

    const renderSummaryStats = (quizStats: IQuizStats, allRounds = false): JSX.Element => {
      let startedAt = quizStats.startedAt;
      const completedAt = quizStats.completedAt;
      let duration = `Duration: ${quizStats.duration} `;
      let averageSeconds = quizStats.averageSeconds;

      if (allRounds) {
        const roundStatsList = stats.prevQuizStats ? [...stats.prevQuizStats] : [];
        roundStatsList.push(stats);
        startedAt = stats.prevQuizStats ? stats.prevQuizStats[0].startedAt : stats.startedAt;
        duration = '';
        const averages = roundStatsList.map(s => s.averageSeconds);
        averageSeconds = averages.reduce((sum, value) => sum + value, 0) / averages.length;
      }

      return (
        <Box sx={{ fontSize: '8pt' }}>
          <Box>Started: {`${strftime('%F %T', startedAt)}`}</Box>
          <Box>Completed: {`${strftime('%F %T', completedAt || new Date())}`}</Box>
          <Box>{duration}(average: {averageSeconds})</Box>
        </Box>
      );
    };

    const renderTakeAgain = (quizStats: IQuizStats, allRounds = false): JSX.Element => {
      return (
        <Box display="inline-flex" sx={{ marginTop: '12px', minWidth: '350px'}}>
          <Button
            sx={{
              color: 'white',
              marginLeft: '20px',
              marginRight: '20px',
              backgroundColor: '#048a83',
              '&:hover': {
                backgroundColor: '#0db2ab',
              }
            }}
            size="sm"
            onClick={takeAgain}
          >
            Take Again
          </Button>
          {renderSummaryStats(quizStats, allRounds)}
        </Box>
      );
    };

    const renderFocusModeSummary = (): JSX.Element => {
      const roundStatsList = stats.prevQuizStats ? [...stats.prevQuizStats] : [];
      roundStatsList.push(stats);

      return (
        <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{ width: '80%' }}>
          <Box display="flex" alignItems="flex-start" flexDirection="row" sx={{ width: '100%', overflowX: 'scroll' }}>
            {
              roundStatsList.map((roundStats: IQuizStats, index: number) => {
                return (
                  <table key={`tab_${index}`} style={{
                    width: '100%',
                    marginLeft: '10pt'
                  }}>
                    <thead>
                      <tr>
                        {
                          renderTableHeaderCell('retry', `Round ${index + 1}`)
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {
                          renderTableCell('retry', roundStats, true)
                        }
                      </tr>
                    </tbody>
                  </table>
                );
              })
            }
          </Box>
          {renderTakeAgain(stats, true)}
          {renderDuplicates()}
        </Box>
      );
    };

    const renderNormalSummary = (): JSX.Element => {
      const states: QuizItemState[] = quizScope === 'newOnly' ? ['new'] : ['new', 'retry', 'confirm', 'good', 'mastered'];

      return (
        <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{ width: '100%' }}>
          <Box sx={{ width: '80%' }}>
            <table style={{
              width: '100%',
              marginLeft: '10pt'
            }}>
              <thead>
                <tr>
                  {
                    states.map((s: QuizItemState) => renderTableHeaderCell(s))
                  }
                </tr>
              </thead>
              <tbody>
                <tr>
                  {
                    states.map((s: QuizItemState) => renderTableCell(s, stats))
                  }
                </tr>
              </tbody>
            </table>
            {renderTakeAgain(stats)}
          </Box>
          {renderDuplicates()}
        </Box>
      );
    };

    if (quizScope === 'focusMode') {
      return renderFocusModeSummary();
    } else {
      return renderNormalSummary();
    }
  };

  const renderSpinner = (): JSX.Element => {
    return (
      <Box display="flex" sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
        <Oval
          height={150}
          width={150}
          color="#0db2ab"
          secondaryColor="#048a83"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />

        <Box sx={{ marginLeft: '-112px' }}>
          Loading...
        </Box>
      </Box>
    );
  };

  const renderDuplicates = (): JSX.Element => {
    if (duplicates.length) {
      const renderDupLocations = (dupEntries: IQuizEntry[]): JSX.Element => {
        return (
          <Box sx={{ marginLeft: '12px', fontSize: '11pt' }}>
            {
              dupEntries.map((dup: IQuizEntry, index: number) => {
                return (
                  <div key={`duplicate_loc_${index}`}>
                    <span>{`${dup.sheetName}`}</span>
                    <span style={{color: '#a9d0ce'}}>{` [ ${dup.index} ]`}</span>
                  </div>
                );
              })
            }
          </Box>
        );
      };

      return (
        <Box display="flex" sx={{
          justifyContent: 'left',
          alignItems: 'left',
          width: '100%'
        }}>
          <Box sx={{ marginTop: '20px', marginLeft: '6px' }}>
            <Box sx={{ color: '#fa2d2d', fontWeight: 'bold', marginBottom: '10px' }}>
              Duplicates detected:
            </Box>
            {
              duplicates.map((dup: IQuizEntryDuplicate, index: number) => {
                return (
                  <Box key={`duplicate_${index}`} sx={{ marginTop: '14px'}}>
                    {dup.quizEntry}: {renderDupLocations(dup.quizEntries)}
                  </Box>
                );
              })
            }
          </Box>
          <Box sx={{ marginTop: '6px', marginLeft: '20px' }}>
            <Button
              sx={{
                color: 'white',
                marginTop: '10px',
                marginLeft: '4px',
                backgroundColor: '#048a83',
                '&:hover': {
                  backgroundColor: '#0db2ab',
                },
                '&:disabled': {
                  backgroundColor: '#0db2ab',
                }
              }}
              size="sm"
              key={'scope_refresh'}
              onClick={takeAgain}
            >
              Refresh
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <div/>
    );
  };

  const renderSelectQuizScope = (): JSX.Element => {
    const selectScope = (value: QuizScope) => {
      setQuizScope(value);
    };

    const options: { label: string, value: QuizScope, default: boolean, disabled: boolean }[] = [
      {
        label: 'Start',
        value: 'start',
        default: false,
        disabled: !prevSession,
      },
      {
        label: 'Start (New items only)',
        value: 'newOnly',
        default: false,
        disabled: !prevSession,
      },
      {
        label: 'Start (Focus mode)',
        value: 'focusMode',
        default: false,
        disabled: !prevSession,
      },
    ];

    // add resume previous session button
    if (!prevSession) {
      // on load, looking for previous sessions, inform via disabled button
      options.push({
        label: 'Resume session: searching...',
        value: 'resume',
        default: false,
        disabled: true,
      });
    } else if (prevSession.startsWith('testing ')) {
      // previous session found, display timestamp and activate button (unless dups)
      const timestamp = prevSession.replace(/^testing /, '');
      options.push({
        label: `Resume session: ${timestamp}`,
        value: 'resume',
        default: true,
        disabled: duplicates.length > 0,
      });
    } else {
      // no previous session found, have disabled button
      options[0].default = true;
      options.push({
        label: `Resume session: ${prevSession}`,
        value: 'resume',
        default: false,
        disabled: true,
      });
    }

    const renderStartButtons = (): JSX.Element => {
      return (
        <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{ mt: 3 }}>
          {
            options.map(opt => {
              return (
                <Button
                  sx={{
                    color: 'white',
                    marginTop: '10px',
                    marginLeft: '4px',
                    backgroundColor: opt.disabled ? 'grey' : '#048a83',
                    '&:hover': {
                      backgroundColor: opt.disabled ? 'grey' : '#0db2ab',
                    },
                    '&:disabled': {
                      backgroundColor: opt.disabled ? 'grey' : '#0db2ab',
                    }
                  }}
                  size="sm"
                  key={`scope_${opt.value}`}
                  onClick={() => selectScope(opt.value)}
                  autoFocus={opt.default}
                  disabled={opt.disabled}
                >
                  {opt.label}
                </Button>
              );
            })
          }
        </Box>
      );
    };

    return (
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        {renderStartButtons()}
        {renderDuplicates()}
      </Box>
    );

  };

  const renderMainPanel = (): JSX.Element => {
    if (quizSetEntryIndex >= 0) {
      if (quizSet?.setEntries?.length) {
        return renderQuiz();
      } else {
        return renderEmpty();
      }
    } else if (isComplete) {
      return renderSummary();
    }
    return renderSpinner();
  };

  const renderProgress = (): JSX.Element => {
    const correctPercent = Math.round((stats.correctCount || 0) / (stats.completed || 1) * 100) || 0;
    return (
      <Box sx={{
        width: 120,
        paddingRight: '28px'
      }}>
        <Box sx={{
          width: 100,
          height: 100,
          marginLeft: '21px'
        }}>
          <CircularProgressbarWithChildren
            value={correctPercent || 0}
            text={`${correctPercent || 0}%`}
          >
            <div style={{
              marginTop: '36px',
              fontSize: '9pt',
              color: 'grey'
            }}>Correct
            </div>
          </CircularProgressbarWithChildren>
        </Box>
        <Box display="flex" sx={{ width: 140 }}>
          <Box sx={{
            width: 65,
            height: 65,
            margin: '3px'
          }}>
            <CircularProgressbar
              value={stats.missedPercentage || 0}
              text={`${stats.missedPercentage || 0}%`}
              styles={buildStyles({
                pathColor: '#cfcfcf',
                textColor: '#eaa4a4',
                trailColor: '#ff0000',
              })}
            />
          </Box>
          <Box sx={{
            width: 65,
            height: 65,
            margin: '3px'
          }}>
            <CircularProgressbar
              value={stats.correctPercentage || 0}
              text={`${stats.correctPercentage || 0}%`}
              styles={buildStyles({
                pathColor: '#cfcfcf',
                textColor: 'rgba(0,255,0,0.81)',
                trailColor: 'rgba(0,255,0,0.56)',
              })}
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: '6px', marginLeft: '21px', color: 'white' }}>
          <LinearProgress determinate color="primary" variant="soft" thickness={5} value={stats.totalPercentage}/>
          <Box sx={{textAlign: 'center', marginTop: '2px', fontSize: '9pt'}}>{stats.totalPercentage || 0}%</Box>
        </Box>
      </Box>
    );
  };


  const renderLog = (): JSX.Element => {
    return (
      <Box sx={{overflowY: 'scroll', height: '400px', marginTop: '20px'}}>
        <pre>
          {log}
        </pre>
      </Box>
    );
  };

  if (processingError) {
    return (
      <Box display="flex" sx={{ flexDirection: 'column' }}>
        <Box display="inline-flex" sx={{ marginTop: '12px', }}>
          <Box display="flex" sx={{ justifyContent: 'left', marginLeft: '40px', color: 'red', fontSize: '16pt' }}>
            Sorry, an error occurred:
          </Box>
          <Button
            sx={{
              color: 'white',
              marginLeft: '20px',
              marginRight: '20px',
              backgroundColor: '#048a83',
              '&:hover': {
                backgroundColor: '#0db2ab',
              }
            }}
            size="sm"
            onClick={takeAgain}
          >
            Reset
          </Button>
        </Box>

        <Box sx={{overflowY: 'scroll', height: '400px', marginTop: '20px', marginLeft: '40px', fontSize: '10pt', justifyContent: 'left'}}>
          <pre>
            {processingError.message}
          </pre>
          <hr/>
          <pre>
            {processingError.stack}
          </pre>
        </Box>
      </Box>
    );
  }

  // if no scope selected, present scope choice screen
  if (!quizScope) {
    return (
      <div className="Quiz">
        <Box display="flex" sx={{ justifyContent: 'center' }}>
          {renderSelectQuizScope()}
        </Box>
      </div>
    );
  }

  // render main quiz screen
  return (
    <div className="Quiz">
      <Box display="flex" sx={{ justifyContent: 'space-between' }}>
        {renderMainPanel()}
        {renderProgress()}
      </Box>
      {QUIZ_TRACE_ENABLED ? renderLog() : ''}
    </div>
  );
};

export default Quiz;

