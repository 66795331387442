import bcrypt from 'bcryptjs';

export type LangSpreadsheets = { [key: string]: string };

export class User {
  constructor(private _name: string, private _password: string, private _langSpreadsheets: LangSpreadsheets) {}

  get name(): string {
    return this._name;
  }

  get password(): string {
    return this._password;
  }

  get langSheets(): LangSpreadsheets {
    return this._langSpreadsheets;
  }

  get languages(): string[] {
    return Object.keys(this._langSpreadsheets);
  }

  spreadsheetIdForLanguage(language: string): string {
    return this._langSpreadsheets[language];
  }

  passwordMatch(password: string): boolean {
    return bcrypt.compareSync(password, this._password);
  }
}
